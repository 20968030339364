import React from 'react';
import styled from 'styled-components';

const CostListWrapper = styled.div`
    width: 100%;
    .label{
        font-family: Oswald;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        @media (max-width: 1300px){
            font-size: 14px;
        }
    }

    .amount{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--darkgreen);

        width: 20%;
        display: flex;
        justify-content: flex-start;
        
        @media (max-width: 1300px){
            font-size: 14px;
        }
    }

    .cost-row{
        display: flex;
        justify-content: space-between;
        margin: 16px 0px;
        @media (max-width: 1300px){
            margin: 5px 0px;
        }
    }

    .tax .label {
            text-transform: capitalize;
    }

    hr{ 
        height: 1px;
        border: 0px;
        background-color: #B1B2B3;
        opacity: 0.5;
    }

    .amount{
        display: flex;
        justify-content: flex-end;
    }

    .discounts{
        .label{
            font-weight: 900;
            
        }
        .amount{
            font-weight: 900;
            
        }
    }

    .total{
        .label{
            text-transform: uppercase;
        }
        .amount{
            font-weight: 900;
        }
    }
`;

export default function TotalCostList({subtotal, taxes, discounts, total}){
    return(
        <CostListWrapper>
            <hr />
            <div className="subtotal cost-row">
                <div className="label">Subtotal</div>
                <div className="amount">${subtotal.toFixed(2)}</div>
            </div>
            <div className="taxes">
                    <div className="tax cost-row">
                        <div className="label">
                            {/* {taxes.name + ` (`+ taxes.percent.toFixed(2)+`%)`} */}
                            Tax
                        </div>
                        <div className="amount">
                            {/* ${(subtotal * (tax.percent/100)).toFixed(2)} */}
                            ${taxes.toFixed(2)}
                        </div>
                    </div>
            </div>
            <div className="discounts">
                    <div className="discounts cost-row">
                        <div className="label">
                            {/* {taxes.name + ` (`+ taxes.percent.toFixed(2)+`%)`} */}
                            Discount
                        </div>
                        <div className="amount">
                            {/* ${(subtotal * (tax.percent/100)).toFixed(2)} */}
                            - ${discounts.toFixed(2)}
                        </div>
                    </div>
            </div>
            <hr />
            <div className="total cost-row">
                <div className="label">total</div>
                <div className="amount">${total.toFixed(2)}</div>
            </div>
        </CostListWrapper>
    )
}