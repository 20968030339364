/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { CheckoutContext } from '../apolloMenu/contexts/checkout';
import QuantityWidget from './QuantityWidget';
import exitX from '../../assets/images/x.svg';

import ProductPromoContext from '../../contexts/ProductPromoContext';

import { addToCartSurf, removeFromCartSurf } from '../../hooks/useSurfside';

const MOBILE_WIDTH = `900px`;
const LAPTOP_WIDTH = '1300px';

const CartProductStyle = styled.div`
  display: grid;
  grid-template-columns: 60% 20% 20%;
  .item {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .close img {
      height: 70%;
      width: 70%;
      min-height: 13px;
      min-width: 13px;
      padding: 3px;
      &:hover {
        cursor: pointer;
        background-color: #c46e7e;
        border-radius: 4px;
      }
    }
  }
  .quantity {
  }
  .price {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--darkgreen);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    display: none;
  }
`;

export default function CartProduct({ item }) {
  const { removeFromCart, updateQuantity } = useContext(CheckoutContext);
  const [quantityState, setQuantityState] = useState(item.quantity);
  const {
    prodLimit,
    removeLimit,
    remainingLimit,
    limit,
    isInLimit,
    isInPromoLimitProducts,
    PromoLimitProducts,
    increaseProductCount,
    decreaseProductCount,
  } = useContext(ProductPromoContext);

  // useEffect(() => {
  //   if (!checkoutLoading) {
  //     checkout.
  //   }
  // }, [checkout, checkoutLoading])

  return (
    <CartProductStyle>
      <>
        <div className="item">
          <div
            className="close"
            onClick={() => {
              removeFromCart(item.id);
              removeLimit(item.product.id);
              removeFromCartSurf(item.product, item.option, item.quantity);
            }}
          >
            <img src={exitX} alt="cartClose" />
          </div>
          <div>{`${item.product.name}${
            item.product.option ? `-${item.option}` : ''
          }`}</div>
        </div>
        <div className="quantity">
          <QuantityWidget
            quantity={item.quantity}
            setQuantity={(value) => {
              updateQuantity(item.id, value);
            }}
            qlimit={
              isInPromoLimitProducts(item.product.id) ? remainingLimit() : 9
            }
            fromCart
            id={item.product.id}
            oglimit={prodLimit}
            addToCartSurf={addToCartSurf}
            product={item.product}
            option={item.option}
          />
        </div>
        <div className="price">
          {item.product.variants.length === 1
            ? `$${(item.product.variants[0].priceRec * item.quantity).toFixed(
                2
              )}`
            : `$${(
                item.product.variants[
                  item.product.variants
                    .map((v) => v.option)
                    .indexOf(item.option)
                ].priceRec * item.quantity
              ).toFixed(2)}`}
        </div>
      </>
    </CartProductStyle>
  );
}
