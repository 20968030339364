/* eslint-disable no-shadow */
import React, { useState } from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ADULT_STATE } from './TotalPopup2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 50px;
  margin-bottom: 1vh;

  .title {
    text-align: center;
    margin: 0;
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
  }

  .subtext {
    margin-top: 8px;
    text-transform: uppercase;
    text-align: center;
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
  }

  .age {
    margin-top: 29px;
    margin-bottom: 25px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    display: flex;
    button {
      &.active{
        background-color: #B0E640;
        color: white;
      }
      @media (max-width: 500px) {
        width: 50%;
        padding: 40px 40px;
      }
      &:first-child {
        margin-right: 10px;
      }
      padding: 40px 0;
    }
  }
  .slider {
    width: 100%;
  }
  @media (max-width: 1000px) {
    margin-top: 10px;
    margin-bottom: 10px;
    .title {
      font-size: 12px;
    }

    .subtext {
      font-size: 22px;
    }

    .age-buttonwrapper {
      width: 75%;
    }
    .age {
      font-size: 50px;
    }
    .slider {
      width: 80%;
    }
  }
`;

export default function AgeCheck({ adultState, isNineteen, setIsNineteen, setAdult }) {
  return (
    <Wrapper>
      {adultState === ADULT_STATE.notAdult ? (
        <>
          <div className="subtext">Sorry, come back when you're 19.</div>
          <button
            onClick={() => {
              setAdult(ADULT_STATE.unset);
              setIsNineteen(null);
            }}
          >
            {' '}
            RESET AGE{' '}
          </button>
        </>
      ) : (
        <>
          <div className="title">You Must Be At Least 19 To Enter The Website</div>
          <div className="subtext">Are you 19?</div>
          <div className="age">
            <button
              className={isNineteen === true ? 'active' : ''}
              onClick={() => setIsNineteen(true)}
            >
              Yes
            </button>
            <button
              className={isNineteen === false ? 'active' : ''}
              onClick={() => setIsNineteen(false)}
            >
              No
            </button>
          </div>
        </>
      )}
    </Wrapper>
  );
}
