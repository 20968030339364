/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ProductPromoContext from '../../contexts/ProductPromoContext';
import { removeFromCartSurf, addToCartSurf } from '../../hooks/useSurfside';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  .box {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #ffffff;
    border: 1px solid var(--darkgreen);
    box-sizing: border-box;
    border-radius: 2px;

    color: var(--darkgreen);

    font-size: 30px;

    padding-bottom: 5px;

    text-align: center;

    width: 30px;
    height: 30px;

    user-select: none;
    -o-user-select: none;

    cursor: pointer;
  }

  .disabled {
    opacity: 0.4;
  }

  .value {
    margin: 0px 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }
`;

export default function QuantityWidget({
  quantity,
  setQuantity,
  qlimit = 9,
  fromCart,
  id,
  oglimit,
  product,
  option,
}) {
  const { increaseProductCount, decreaseProductCount } =
    useContext(ProductPromoContext);

  return (
    <Wrapper>
      <div
        className={quantity > 1 ? `box` : `box disabled`}
        onClick={() => {
          quantity > 1 && setQuantity(quantity - 1);
          if (fromCart && id) {
            decreaseProductCount(1, id);
            removeFromCartSurf(product, option, 1);
          }
        }}
      >
        -
      </div>
      <div className="value">{quantity}</div>
      {qlimit > 0 && quantity < qlimit ? (
        <div
          className="box"
          onClick={() => {
            setQuantity(quantity + 1);
            if (fromCart && id) {
              increaseProductCount(1, id);
            }
            if (fromCart) {
              addToCartSurf(product, option, 1);
            }
          }}
        >
          +
        </div>
      ) : (
        <></>
      )}
    </Wrapper>
  );
}
