import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100vw;

    background: var(--darkgreen);

    padding: 15px 0;
    overflow: hidden;

    display: ${(props) => props.showDesktop ? 'inherit' : 'none'};

    @media(max-width: 1000px){
        padding: 10px 0;
        display: ${(props) => props.showMobile ? 'inherit' : 'none'};
        margin-bottom: -10px;
    }
`;

const Row = styled.div`
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;

    animation: ${(props) => `marquee ${props.animationLength} linear infinite`};
    font-size: ${(props) => props.fontSize};

    div {
        display: inline-block;
    }

    @keyframes marquee {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-50%, 0, 0);
        }
    }
`

const Item = styled.div`
    text-transform: uppercase;

    font-family: Oswald;
    color: white;

    margin: 0px 25px;
    @media(max-width: 1000px){
        font-size: 15px;
    }
`;

export default function SlidingBanner({ message, numToRepeat=10, fontSize="18px", animationLength="15s", showDesktop=true, showMobile=true })
{
    const itemsToLoop = (new Array(numToRepeat)).fill(0);
    return(
        <Wrapper showDesktop={showDesktop} showMobile={showMobile}>
            <Row fontSize={fontSize} animationLength={animationLength}>
                {itemsToLoop.map((item, index) => {
                    return(
                        <Item key={`${item}-${index}`}>
                            {message}
                        </Item>
                    )
                })}
            </Row>
        </Wrapper>
    )
}