import { graphql, useStaticQuery } from 'gatsby';
import { filterRetailers } from '../helpers/retailers';

export const useRatailers = () => {
  const locationData = useStaticQuery(graphql`
    query {
      dutchieplus {
        retailers {
          id
          name
          coordinates {
            latitude
            longitude
          }
          address
        }
      }
    }
  `);

  const sortedOptionsList = filterRetailers(
    locationData?.dutchieplus?.retailers.map((_option) => ({
      label: _option.name.substring(23),
      sublabel: _option.address.split(', ').slice(0, 2).join(', '),
      value: _option.id,
    })),
    'label',
  );

  // const sortedOptionsList = [
  //   {
  //     label: ' Barrie, Orillia, Innisfil',
  //     sublabel: '66 Dunlop Street West, Barrie',
  //     value: '882f4295-2c51-4441-97ee-762a8b9e3478',
  //   },
  //   {
  //     label: ' Keswick - York Region',
  //     sublabel: '1 Church Street, Keswick',
  //     value: '0b537b1d-bf7d-4812-a08c-889286c5fe02',
  //   },
  // ];

  const cityToId = (city) => {
    const id = sortedOptionsList.find((item) => item.label.includes(city));
    if (!id) {
      console.log('can not find current city id useRetailers');
      console.error('can not find current city id useRetailers');
    }
    return id;
  };

  return {
    sortedOptionsList,
    cityToId,
  };
};
