/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  border-radius: 3px;
  position: absolute;
  z-index: 10001;
  top: calc(50% - 200px);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 400px;
  width: 600px;

  background-color: var(--gray);

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  padding: 70px 17px;

  .title {
    font-family: Oswald;
    font-size: 28px;
    color: black;
    text-align: center;
    font-weight: 700;
  }
  .body {
    padding: 0px 40px;
    margin: 14px 0px;
    font-family: Oswald;
    font-size: 19px;
    text-align: center;
    line-height: 27px;
    b {
      color: var(--lightgreen);
    }
  }

  @media (max-width: 800px) {
    width: 350px;
  }
`;

const Buttons = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;

  .btn {
    margin: 10px 10px;
    width: 200px;
    text-align: center;
  }

  .no {
    font-weight: 300;
  }
  .yes {
    font-weight: 900;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export default function LocationModal({
  setCurrentRetailer,
  option,
  modalOn,
  setModalOn,
  slug,
  filters,
}) {
  // if (modalYes){
  //     setCurrentRetailer(option)
  //     setFilter("store_ids", option, "all")
  //     setModalYes(false);
  // }
  return (
    <>
      {modalOn ? (
        <Background>
          <Wrapper>
            <div className="title">
              ARE YOU SURE YOU WANT TO CHANGE LOCATIONS?
            </div>
            <div className="body">
              Changing location will result in your <b>cart emptying</b>, are
              you sure you want to change location?
            </div>
            <Buttons>
              <button
                className="btn no hoc-button-outline"
                onClick={() => setModalOn(false)}
              >
                CANCEL
              </button>
              <div
                className="btn yes hoc-button-outline"
                onClick={() => {
                  if (slug) {
                    navigate(
                      `/products/${slug}`,
                      { state: { option } },
                      { replace: true }
                    );
                  }
                  setCurrentRetailer(option);
                  // if(setFilter){
                  //     setFilter("store_id", option, "all")
                  // }
                  setModalOn(false);
                }}
              >
                CHANGE LOCATION
              </div>
            </Buttons>
          </Wrapper>
        </Background>
      ) : (
        <></>
      )}
    </>
  );
}
