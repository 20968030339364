import React, { useContext } from 'react';
import styled from 'styled-components';
import { CheckoutContext } from '../apolloMenu/contexts/checkout';
import CartProduct from './CartProduct';
import MobileCartProduct from './MobileCartProduct';

const MOBILE_WIDTH = `900px`;
const LAPTOP_WIDTH = '1300px';

const ListGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, 40px);
  grid-row-gap: 14px;

  max-height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #eeeeef;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a1c654;
  }

  scrollbar-width: thin;
  scrollbar-color: #a1c654 #eeeeef;

  @media (max-width: ${LAPTOP_WIDTH}) {
    max-height: 150px;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    margin-top: 0px;
    max-height: none;
    height: auto;
    overflow-y: visible;

    display: flex;
    flex-direction: column;
  }
`;

const ListRow = styled.div`
  display: grid;
  grid-template-columns: 60% 20% 20%;

  &.label-row {
    margin-left: 22px;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    opacity: 0.5;
    border-bottom: 1px solid #b1b2b3;

    padding-bottom: 5px;

    align-self: flex-end;
    margin-bottom: 7px;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    display: none;
  }
`;

export default function ProductList() {
  const { checkout } = useContext(CheckoutContext);
  return (
    <>
      <ListRow className="label-row">
        <div>ITEM</div>
        <div>QUANTITY</div>
        <div>PRICE</div>
      </ListRow>
      <ListGrid>
        <>
          {checkout &&
            checkout.items.map((item) => (
              <div key={item.id}>
                <CartProduct item={item} />
                <MobileCartProduct item={item} />
              </div>
            ))}
        </>
      </ListGrid>
    </>
  );
}
