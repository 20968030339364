/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './src/styles/bootstrap-grid/bootstrap-grid.min.css';
import 'swiper/swiper-bundle.min.css';

// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

import '@djthoms/pretty-checkbox';
import 'react-toastify/dist/ReactToastify.css';
import CheckoutProvider from './src/components/apolloMenu/contexts/checkout';
import Wrapper from './gatsby-render-layout';

export function wrapPageElement({ element, props }) {
  return (
    <CheckoutProvider>
      <Wrapper {...props} element={element} />
    </CheckoutProvider>
  );
}

export function onClientEntry(){
  window.onload = () => {
    ;(function(s,u,r,f,i,n,g){if(!s[i]){s.surfContext=s.surfContext||[];
    s.surfContext.push(i);s[i]=function(){(s[i].q=s[i].q||[]).push(arguments)
    };s[i].q=s[i].q||[];n=u.createElement(r);g=u.getElementsByTagName(r)[0];n.async=1;
    n.src=f;g.parentNode.insertBefore(n,g)}}(window,document,"script","//cdn.surfside.io/v0.1.2/edge.min.js","surf"));

    var surf = document.createElement('script');
      surf.setAttribute('src','//cdn.surfside.io/v1.0.0/wave.min.js');
      surf.setAttribute("type","text/javascript");
      surf.setAttribute('id','surfside');
      surf.setAttribute('accountId','71792') //live
      surf.setAttribute('sourceId','4d428')  //live
      surf.setAttribute('platform','digital');
      var sync = document.createElement('img');
      sync.setAttribute('src','//edge.surfside.io/id/uid?');
      sync.setAttribute('height','1');
      sync.setAttribute('width','1');
      sync.setAttribute('display','none');
      document.head.appendChild(surf,sync);
      console.log("[Surf DEBUG 🌊]: Surfs Up!")
  }
}