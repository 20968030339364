
import Geocode from 'react-geocode';



export const cities = [
  // Toronto: {
  //   lat: 43.6559892,
  //   lng: -79.3996637,
  // },
  // Kitchener: {
  //   lat: 43.4507155,
  //   lng: -80.4946477,
  // },
  {
    label: ' Barrie, Orillia, Innisfil',
    lat: 44.3893954,
    lng: -79.6897951,
  },
  // Kingston: {
  //   lat: 44.2342596,
  //   lng: -76.4956145,
  // },
  {
    label: ' Keswick - York Region',
    lat: 44.2471678,
    lng: -79.4526463,
  },
];


export const getClosestCity = (lat, lng) => {
  const [  Barrie, Keswick ] = cities;
  const distances = [
    // (Toronto.lat - lat) ** 2 + (Toronto.lng - lng) ** 2,
    // (Kitchener.lat - lat) ** 2 + (Kitchener.lng - lng) ** 2,
    (Barrie.lat - lat) ** 2 + (Barrie.lng - lng) ** 2,
    // (Kingston.lat - lat) ** 2 + (Kingston.lng - lng) ** 2,
    (Keswick.lat - lat) ** 2 + (Keswick.lng - lng) ** 2,
  ];
  const minDistance = Math.min(...distances);
  const cityIndex = distances.findIndex((distance) => distance === minDistance);

  return cities[cityIndex].label;
};

export const getClosestCityByPostcode = async postcode => {
  Geocode.setApiKey("AIzaSyDHdRRXlkEtxxkihYsWmGqwt7SdTenC-EI");
  return Geocode.fromAddress(postcode).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;

      return    getClosestCity(lat, lng);

    },
    (error) => {
      console.error(error);
      return null;
    }
  );
}