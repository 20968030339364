import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby-link';
import { FaSearch, FaMapMarkerAlt } from 'react-icons/fa';
import { useLocation } from '@reach/router';
import { setSearch } from '../../utils/menu/setFilters';
import { CheckoutContext } from '../apolloMenu/contexts/checkout';
import LocationPicker from './LocationPicker';

const MOBILE_BREAKPOINT = '768px';

const Wrapper = styled.div`
  height: 65px;
  width: 55vw;

  display: ${({ showDesktop }) => (showDesktop ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: ${({ showMobile }) => (showMobile ? 'flex' : 'none')};

    width: 95vw;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  align-items: center;

  //position: relative;

  width: 100%;
  /* @media (max-width: 1000px){
        width: 80vw;
    } */

  background: #f5f5f5;
  border-radius: 15px;

  padding: 0 15px;

  .divider {
    height: 15px;
    width: 0px;
    margin: 0 10px;

    border: 1px solid rgba(109, 110, 113, 0.5);
  }

  @media (max-width: 1000px) {
    width: 100%;

    .divider {
      display: none;
    }
  }
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  .searchbar {
    width: 100%;
    background: #f5f5f5;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: Lato;

    padding: 1.5vh 2vh 1.5vh 2vh;
    //border: 1px solid rgba(109, 110, 113, 0.5);
    border-radius: 3px;
  }

  .submit {
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute;
        right: 2.5%; */

    color: rgba(109, 110, 113, 0.5);

    border: none;
    background: none;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export default function MiddleBars({
  showMobile = true,
  showDesktop = true,
  setModalOpen,
  setStagedLocation,
}) {
  const location = useLocation();
  const { retailerList, currentRetailer, setCurrentRetailer } =
    useContext(CheckoutContext);
  const [search, SetSearch] = useState('');
  const [retailer, setRetailer] = useState('');
  const [listofretailers, setListOfRetailers] = useState([]);

  useEffect(() => {
    if (currentRetailer && retailerList) {
      const _curRetailer = retailerList.find(
        (_retailer) => _retailer.id === currentRetailer
      );
      if (_curRetailer)
        setRetailer({
          value: _curRetailer.id,
          label: _curRetailer?.name,
        });

      const _listOfRetailers = retailerList.map((_retailer) => ({
        value: _retailer.id,
        label: _retailer?.name,
      }));
      setListOfRetailers(_listOfRetailers);
    }
  }, [currentRetailer, retailerList]);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    setSearch(search, location);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onHandleSubmit(e);
    }
  };

  return (
    <>
      <Wrapper showMobile={showMobile} showDesktop={showDesktop}>
        <BoxWrapper>
          <SearchBar>
            <button
              type="submit"
              value="Submit"
              className="submit"
              onClick={onHandleSubmit}
            >
              <FaSearch />
            </button>
            <input
              placeholder="Search Products"
              className="searchbar"
              type="text"
              value={search}
              onChange={(e) => SetSearch(e.currentTarget.value)}
              onKeyDown={handleKeyDown}
            />
          </SearchBar>

          <div className="divider" />

          <LocationPicker
            retailer={retailer}
            listofretailers={listofretailers}
            setModalOpen={setModalOpen}
            setStagedLocation={setStagedLocation}
            hide={showMobile}
            setCurrentRetailer={setCurrentRetailer}
          />
        </BoxWrapper>
      </Wrapper>
    </>
  );
}
