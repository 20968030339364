/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { checkoutSurf } from '../../hooks/useSurfside';

const ButtonStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .hoc-button {
    padding: 10px 80px;
    &:hover {
      outline: solid var(--darkgreen) 3px;
      color: var(--darkgreen);
    }
    margin-bottom: 20px;
    @media (max-width: 1300px) {
      margin-bottom: 10px;
    }
  }

  .continue {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-decoration-line: underline;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 1300px) {
      font-size: 13px;
    }
  }
`;

export default function CheckoutBtn({
  setToggle,
  finalurl,
  storeName,
  checkout,
}) {
  // href={finalurl}
  return (
    <ButtonStyle>
      <a
        target="_blank"
        href={finalurl}
        onClick={() => console.log(finalurl)}
        rel="noreferrer"
      >
        <button
          className="hoc-button"
           // ON GTM
          // onClick={() => {
          //   checkoutSurf(storeName, checkout); 
          // }}
        >
          CHECKOUT
        </button>
      </a>
      <div className="continue" onClick={() => setToggle(false)}>
        Continue Shopping
      </div>
    </ButtonStyle>
  );
}
