/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Axios from 'axios';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';

import ReactSelect from 'react-select';

import Cookies from 'universal-cookie';
import ModalsContext from '../../contexts/ModalsContext';

const MOBILE_BREAKPOINT = '768px';

const locationOptions = [
  { value: 3, label: 'Barrie, Orillia, Innisfil' },
  { value: 4, label: 'Keswick - York Region' },
];

const defaultValues = {
  ReactSelect: { value: 3, label: 'Barrie, Orillia, Innisfil' },
};

const paddingSelectStyle = {
  // individual option in dropdown
  option: (provided, state) => ({
    ...provided,
    border: '0px',
    fontSize: '22px',
    fontFamily: 'Oswald',
    textTransform: 'uppercase',
    background: state.isSelected
      ? 'var(--darkgreen)'
      : state.isFocused
      ? 'var(--lightgreen)'
      : 'white',
  }),

  // chosen value
  singleValue: (provided, state, styles) => ({
    ...provided,
    fontSize: '22px',
    fontFamily: 'Oswald',
    textTransform: 'uppercase',
    // "@media only screen and (max-width: 750px)": {
    //     ...styles["@media only screen and (max-width: 750px)"],
    //     fontSize: '25px',
    // },
  }),

  // actual selector
  control: (provided, state) => ({
    ...provided,
    width: 180,
    background: 'rgba(0, 0, 0, 0)',
    fontFamily: 'Oswald',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    border: state.isFocused || state.isSelected ? '1px solid' : '0px',
    borderColor: 'var(--darkgreen) !important',
    outline: state.isFocused || state.isSelected ? '0px' : '0px',
    boxShadow: state.isFocused || state.isSelected ? '0px' : '0px',
  }),

  // the dropdown menu (once expanded)
  menu: (provided, state) => ({
    ...provided,
    width: 180,
    textTransform: 'uppercase',
  }),

  indicatorSeparator: (provided, state) => ({
    display: 'none',
  }),

  dropdownIndicator: (provided, state) => ({
    color: 'black',
    paddingRight: '5px',
  }),
};

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

const XWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  font-size: 25px;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${(props) => (props.isSuccess ? 'none' : 'block')};
  padding: 0px;
  background-color: white;
  border-radius: 3px;
  width: 500px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 325px;
  }
`;

const Title = styled.div`
  padding: 20px;
  padding-bottom: 0px;
  h2 {
    font-size: 80px;
    color: var(--darkgreen);
    font-weight: 900;
  }
  @media (max-width: 900px) {
    h2 {
      font-size: 60px;
    }
  }
`;

const About = styled.div`
  p {
    font-size: 18px;
    color: var(--darkgreen);
    font-family: Oswald;
  }
  max-width: 375px;
  margin: 0px auto;
  margin-bottom: 7px;
  @media (max-width: 900px) {
    p {
      font-size: 16px;
    }
    max-width: 275px;
    margin: 0px auto;
  }
`;

const Form = styled.form`
  margin-bottom: 20px;
  .labeltext {
    font-family: Oswald;
    display: inline-block;
    width: 150px;
  }
  label {
    margin: 20px 0px;
  }
  .locationSelect {
    width: 260px;
    display: inline-block;
    margin-bottom: 40px;
  }
  @media (max-width: 900px) {
    label {
      margin: 40px 0px;
    }
  }
`;

const FormErrorContainer = styled.div`
  position: relative;
  padding: 5px;
`;

const FormError = styled.div`
  position: absolute;
  visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
  transform: ${(props) =>
    props.isActive ? 'translateX(0)' : 'translateX(30px)'};
  font-family: 'Lato';
  font-size: 14px;
  color: red;
  opacity: ${(props) => (props.isActive ? '1' : '0')};
  transition: all ${(props) => (props.isActive ? '0.3s' : '0')};
`;

const SuccessMessage = styled.div`
  display: ${(props) => (props.isSuccess ? 'block' : 'none')};
  text-align: center;
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0px;
  padding-top: 10px;
`;

export default function LoginPopup() {
  const { isModalOpen, setIsModalOpen } = useContext(ModalsContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });
  const cookies = new Cookies();
  const dailyPopup = cookies.get('dailyPopup');

  const onClose = () => {
    setIsModalOpen(false);
    const date = new Date();
    date.setTime(Date.now() + 1000 * 60 * 60 * 24 * 1);
    cookies.set('dailyPopup', true, { path: '/', expires: date });
  };

  const onSubmit = (data) => {
    const postUrl =
      'https://hoc-express-server.netlify.app/.netlify/functions/api/addPersona/';
    const postData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      favoriteStoreID: data.ReactSelect.value,
      disableSMS: true,
      loyalty: false,
    };
    Axios.post(postUrl, postData)
      .then((result) => {
        setIsSuccess(true);
      })
      .catch((error) => {});
    const date = new Date();
    date.setTime(Date.now() + 1000 * 60 * 60 * 24 * 60);
    cookies.set('dailyPopup', true, { path: '/', expires: date });
  };

  return (
    <Container isOpen={isModalOpen}>
      <Overlay onClick={() => onClose()} />
      <Content>
        <XWrapper>
          <GrClose onClick={() => onClose()} />
        </XWrapper>
        <Title>
          <h2>JOIN THE HOUSE</h2>
        </Title>
        <About>
          <p>
            Join our exclusive membership program.
            <br />
            Earn loyalty points for shopping, redeem them for in-store savings.
          </p>
        </About>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <label>
            <div className="labeltext">First Name</div>
            <input
              type="text"
              {...register('firstName', {
                required: true,
              })}
            />
            <FormErrorContainer>
              <FormError isActive={errors.firstName?.type === 'required'}>
                First name is required
              </FormError>
            </FormErrorContainer>
          </label>
          <label>
            <div className="labeltext">Last Name</div>
            <input
              type="text"
              {...register('lastName', {
                required: true,
              })}
            />
            <FormErrorContainer>
              <FormError isActive={errors.lastName?.type === 'required'}>
                Last name is required
              </FormError>
            </FormErrorContainer>
          </label>
          <label>
            <div className="labeltext">Email</div>
            <input
              type="text"
              {...register('email', {
                required: true,
              })}
            />
            <FormErrorContainer>
              <FormError isActive={errors.email?.type === 'required'}>
                Email is required
              </FormError>
            </FormErrorContainer>
          </label>
          <label>
            <div className="labeltext">Location</div>
            <SelectWrapper>
              <Controller
                name="ReactSelect"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={locationOptions}
                    styles={paddingSelectStyle}
                  />
                )}
              />
            </SelectWrapper>
          </label>
          <button className="hoc-button hoc-button--hover-dark" type="submit">
            Submit
          </button>
          <SuccessMessage isSuccess={isSuccess}>You are done!</SuccessMessage>
        </Form>
      </Content>
    </Container>
  );
}
