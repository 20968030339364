import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --darkgreen: #A1C654;
    --lightgreen: #B0E640;
    --black: #2D2B2C;
    --white: #E5E5E5;
    --bgwhite: #f9f9f9;
    --bggray: #E5E5E5;
    --gray: #F5F5F5;

  }
  html {
    font-size: 10px;
    background-color: var(--bgwhite);
  }

  body {
    font-size: 2rem;
    scrollbar-width: thin;
    scrollbar-color: #A1C654 #EEEEEF;
  }



  .p-0{
    padding: 0px 0px;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  .hoc-button{
    background: var(--darkgreen);
    background: linear-gradient(287.58deg, #A1C654 20.01%, #B0E640 91.64%);
    color: white;
    padding: 1rem 5rem;
    cursor: pointer;
    border-radius: 3px;
    border: 0px;
    &:hover {
      color: var(--black);
      background: white;
    }
    &--hover-dark:hover {
      color: white;
      background: linear-gradient(291.45deg, #2D2B2C 48%, #3A3A3A 91.7%);
    }
    &--inline-block {
      display: inline-block;
      padding: 20px 5rem;
    }
  }

  .hoc-button-outline{
    background-color: rgba(0,0,0,0);
    border-radius: 3px;
    padding: 15px 30px;
    cursor: pointer;
    border: 3px solid var(--darkgreen);
    color: var(--darkgreen);
    &:hover {
      background: linear-gradient(287.58deg, #A1C654 20.01%, #B0E640 91.64%);
      padding: calc(15px + 3px) calc(30px + 3px);
      border: 0px;
    }

    &.disabled{
      opacity: 0.1;
      border: 3px solid grey;
      color: grey;
      &:hover {
        background: transparent;
        padding: calc(15px + 3px) calc(30px + 3px);
        border: 3px solid grey;
        padding: 15px 30px;
        cursor: default;
        color: grey;
      }
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  img {
    max-width: 100%;
  }

  .disabled-item{
    opacity: 0.4;
    &:hover{
      cursor: default;
      h3{
        text-decoration: none !important;
      }
    }
  }
  .container {
    width: 100%;
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 10px;
  }
`;

export default GlobalStyles;
