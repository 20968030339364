// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barrie-delivery-js": () => import("./../../../src/pages/barrie/delivery.js" /* webpackChunkName: "component---src-pages-barrie-delivery-js" */),
  "component---src-pages-barrie-js": () => import("./../../../src/pages/barrie.js" /* webpackChunkName: "component---src-pages-barrie-js" */),
  "component---src-pages-barrie-location-js": () => import("./../../../src/pages/barrie/location.js" /* webpackChunkName: "component---src-pages-barrie-location-js" */),
  "component---src-pages-barrie-menu-js": () => import("./../../../src/pages/barrie/menu.js" /* webpackChunkName: "component---src-pages-barrie-menu-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-curated-menu-js": () => import("./../../../src/pages/curated-menu.js" /* webpackChunkName: "component---src-pages-curated-menu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keswick-delivery-js": () => import("./../../../src/pages/keswick/delivery.js" /* webpackChunkName: "component---src-pages-keswick-delivery-js" */),
  "component---src-pages-keswick-js": () => import("./../../../src/pages/keswick.js" /* webpackChunkName: "component---src-pages-keswick-js" */),
  "component---src-pages-keswick-location-js": () => import("./../../../src/pages/keswick/location.js" /* webpackChunkName: "component---src-pages-keswick-location-js" */),
  "component---src-pages-keswick-menu-js": () => import("./../../../src/pages/keswick/menu.js" /* webpackChunkName: "component---src-pages-keswick-menu-js" */),
  "component---src-pages-kingston-delivery-js": () => import("./../../../src/pages/kingston/delivery.js" /* webpackChunkName: "component---src-pages-kingston-delivery-js" */),
  "component---src-pages-kingston-js": () => import("./../../../src/pages/kingston.js" /* webpackChunkName: "component---src-pages-kingston-js" */),
  "component---src-pages-kingston-location-js": () => import("./../../../src/pages/kingston/location.js" /* webpackChunkName: "component---src-pages-kingston-location-js" */),
  "component---src-pages-kingston-menu-js": () => import("./../../../src/pages/kingston/menu.js" /* webpackChunkName: "component---src-pages-kingston-menu-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-near-me-js": () => import("./../../../src/pages/near-me.js" /* webpackChunkName: "component---src-pages-near-me-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-stores-js": () => import("./../../../src/pages/stores.js" /* webpackChunkName: "component---src-pages-stores-js" */),
  "component---src-pages-termsofservice-js": () => import("./../../../src/pages/termsofservice.js" /* webpackChunkName: "component---src-pages-termsofservice-js" */),
  "component---src-pages-toronto-delivery-js": () => import("./../../../src/pages/toronto/delivery.js" /* webpackChunkName: "component---src-pages-toronto-delivery-js" */),
  "component---src-pages-toronto-js": () => import("./../../../src/pages/toronto.js" /* webpackChunkName: "component---src-pages-toronto-js" */),
  "component---src-pages-toronto-location-js": () => import("./../../../src/pages/toronto/location.js" /* webpackChunkName: "component---src-pages-toronto-location-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-categorypage-js": () => import("./../../../src/templates/categorypage.js" /* webpackChunkName: "component---src-templates-categorypage-js" */),
  "component---src-templates-delivery-page-js": () => import("./../../../src/templates/deliveryPage.js" /* webpackChunkName: "component---src-templates-delivery-page-js" */),
  "component---src-templates-featured-brand-js": () => import("./../../../src/templates/featuredBrand.js" /* webpackChunkName: "component---src-templates-featured-brand-js" */)
}

