import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Storefront from '../../../static/storefront.svg';
import MapPinLine from '../../../static/mapPinLine.svg';
import Package from '../../../static/package.svg';
import { filterRetailers } from '../../helpers/retailers';

const MOBILE_BREAKPOINT = '960px';

const data = filterRetailers([
  {
    title: 'Kitchener',
    id: 'kitchener',
    content: [
      {
        title: 'About Store',
        type: 'about',
        slug: '/kitchener/',
      },
      {
        title: 'Store Location',
        type: 'location',
        slug: '/kitchener/location/',
      },
      {
        title: 'Kitchener Delivery',
        type: 'delivery',
        slug: '/kitchener/delivery/',
      },
    ],
  },
  {
    title: 'Toronto',
    id: 'toronto',
    content: [
      {
        title: 'About Store',
        type: 'about',
        slug: '/toronto/',
      },
      {
        title: 'Store Location',
        type: 'location',
        slug: '/toronto/location',
      },
      {
        title: 'Toronto Delivery',
        type: 'delivery',
        slug: '/toronto/delivery',
      },
    ],
  },
  {
    title: 'Barrie',
    id: 'barrie',
    content: [
      {
        title: 'About Store',
        type: 'about',
        slug: '/barrie/',
      },
      {
        title: 'Store Location',
        type: 'location',
        slug: '/barrie/location',
      },
      {
        title: 'Barrie Delivery',
        type: 'delivery',
        slug: '/barrie/delivery',
      },
    ],
  },
  {
    title: 'Kingston',
    id: 'kingston',
    content: [
      {
        title: 'About Store',
        type: 'about',
        slug: '/kingston/',
      },
      {
        title: 'Store Location',
        type: 'location',
        slug: '/kingston/location',
      },
      {
        title: 'Kingston Delivery',
        type: 'delivery',
        slug: '/kingston/delivery',
      },
    ],
  },
  {
    title: 'Keswick',
    id: 'keswick',
    content: [
        {
            title: 'About Store',
            type: 'about',
            slug: '/keswick/'
        },
        {
            title: 'Store Location',
            type: 'location',
            slug: '/keswick/location'
        },
        {
            title: 'Keswick Delivery',
            type: 'delivery',
            slug: '/keswick/delivery'
        },
    ]
  },
], 'title');

const Container = styled.div`
  position: absolute;
  top: 180px;
  left: 0;
  z-index: 100;
  visibility: ${(props) => (props.id === props.dropdownState ? 'visible' : 'hidden')};
  width: 100vw;
  max-height: ${(props) => (props.id === props.dropdownState ? '100%' : '0')};
  max-height: 100%;
  background-color: #fafafa;
  box-shadow: inset 0px 4px 15px 5px rgba(60, 100, 177, 0.05);
  overflow: hidden;
  transition: max-width 1s, max-height 1s;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    top: 0;
    right: 0;
    left: unset;
    max-width: ${(props) => (props.id === props.dropdownState ? '2000px' : '0')};
    height: 100%;
    max-height: 100%;
    background-color: white;
  }
`;

const Content = styled.div`
  display: grid;
  visibility: inherit;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: repeat(${(props) => props.rowNumber}, minmax(0, 50px)) 1fr;
  padding: 50px;
  padding-right: 0;
  text-align: left;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    padding-left: 30px;
  }
`;

const ContentTitle = styled.h2`
  margin: 20px 0 40px;
  display: none;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #2d2b2c;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: block;
  }
`;

const TabTitle = styled.div`
  grid-column: 1;
  margin-bottom: 35px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  text-decoration: ${(props) => (props.isSelected ? 'underline' : 'none')};
  color: ${(props) => (props.isSelected ? '#2D2B2C' : '#6D6E71')};
  cursor: pointer;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin: 0;
    font-size: 20px;
    text-transform: none;
    text-decoration: none;
    color: black;
    background-color: ${(props) => (props.isSelected ? '#FAFAFA' : 'white')};
  }
`;

const TabContent = styled.div`
  grid-column: 2;
  grid-row: 1 / -1;
  display: grid;
  visibility: ${(props) => (props.id === props.tabState ? 'inherit' : 'hidden')};
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  padding-right: 50px;
  text-transform: none;
  overflow: hidden;
  transition: max-height ${(props) => (props.id === props.tabState ? '1s' : '0')};
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: unset;
    grid-row: unset;
    display: block;
    max-height: ${(props) => (props.id === props.tabState ? '500px' : '0')};
    padding: 0;
  }
`;

const TabOption = styled(Link)`
  display: flex;
  align-items: center;
  height: 130px;
  padding: 10px 35px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  white-space: nowrap;
  color: black;
  background-color: white;
  border-radius: 3px;
  &.shop-in {
    color: white;
    background-color: #2d2b2c;
  }
  img {
    width: 43px;
    height: 43px;
    margin-left: 20px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0;
    font-weight: normal;
    background-color: #fafafa;
    &.shop-in {
      color: black;
      background-color: #fafafa;
    }
    img {
      display: none;
    }
  }
`;

export default function DropdownStores({ id, dropdownState, setIsMenuOpen, setSelectedDropdown }) {
  const [selectedTab, setSelectedTab] = useState('');

  return (
    <Container id={id} dropdownState={dropdownState}>
      <Content rowNumber={5}>
        <ContentTitle>Stores</ContentTitle>
        {data.map((dropdownTab) => (
          <>
            <TabTitle
              onClick={() => setSelectedTab(`${id}-${dropdownTab.id}`)}
              isSelected={`${id}-${dropdownTab.id}` === selectedTab}
            >
              {dropdownTab.title}
            </TabTitle>
            <TabContent id={`${id}-${dropdownTab.id}`} tabState={selectedTab}>
              {dropdownTab.content.map((dropdownTabOption) => (
                <TabOption
                  key={`${Math.random() * 1000}`}
                  to={dropdownTabOption.slug}
                  className={dropdownTabOption.type}
                  onClick={() => {
                    setSelectedDropdown('');
                    setIsMenuOpen(false);
                  }}
                >
                  {dropdownTabOption.title}
                  {dropdownTabOption.type === 'about' && <img src={Storefront} />}
                  {dropdownTabOption.type === 'location' && <img src={MapPinLine} />}
                  {dropdownTabOption.type === 'delivery' && <img src={Package} />}
                </TabOption>
              ))}
            </TabContent>
          </>
        ))}
      </Content>
    </Container>
  );
}