import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import mapIcon from '../../assets/images/mapIcon.svg';

const Location = styled.div`
  width: fit-content;
  min-width: 50%;

  display: ${(props) => (props.hide ? 'none' : 'flex')};
  align-items: center;

  padding: 0 0px 0 15px;

  .icon {
    color: rgba(109, 110, 113, 0.5);
  }

  .text {
    margin: 0 5px 0 10px;
    text-transform: capitalize;

    font-family: Lato;
    font-size: 16px;

    width: fit-content;
  }

  /* @media(max-width: 1000px){
        display: none;
    } */
`;

const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: 150,
    height: '100%',
    fontFamily: 'Lato',
    fontSize: 13,
    backgroundColor: '#F5F5F5',
  }),

  control: (provided) => ({
    ...provided,
    padding: '0',
    backgroundColor: '#F5F5F5',
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
    color: 'black',
  }),

  valueContainer: (provided) => ({
    ...provided,
    background: '#F5F5F5',
    color: 'black',
    width: '100%',
    padding: 0,
  }),

  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'Lato',
    fontSize: 16,
    textTransform: 'capitalize',
    color: 'black',
    fontWeight: 600,
  }),

  menuPortal: (provided) => ({
    ...provided,
    zIndex: 100,
  }),

  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    fontSize: 16,
    textTransform: 'capitalize',
    color: 'black',
    borderRadius: 0,
    backgroundColor: '#F5F5F5',
  }),

  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'gray' : 'black',
    backgroundColor: '#F5F5F5',
    ':hover': {
      backgroundColor: 'gray',
    },
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export default function LocationPicker({
  hide,
  retailer,
  listofretailers,
  setModalOpen,
  setStagedLocation,
}) {
  const onHandleLocationChange = (option) => {
    // setCurrentRetailer(option.value);
    setModalOpen(true);
    setStagedLocation(option.value);
  };
  return (
    <>
      <Location hide={hide}>
        <div className="icon">
          <img src={mapIcon} alt="mapIcon" />
        </div>

        <p className="text">Current Store:</p>
        <Select
          onChange={onHandleLocationChange}
          value={retailer}
          options={listofretailers}
          styles={selectStyles}
        />
      </Location>
    </>
  );
}
