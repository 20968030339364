import React from 'react';
import styled from 'styled-components';

const CurrentShopStyle = styled.div`
  width: 100%;
  border: 1px solid #6d6e71;
  border-radius: 2px;

  margin-top: 10px;

  .label {
    background-color: var(--darkgreen);
    color: white;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;

    height: 30px;

    margin-bottom: 16px;

    @media (max-width: 1300px) {
      font-size: 10px;
      margin-bottom: 7px;
      height: 22px;
    }
  }

  .city {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: var(--darkgreen);
    text-transform: capitalize;

    text-align: center;

    margin-bottom: 12px;
    @media (max-width: 1300px) {
      font-size: 17px;
      margin-bottom: 7px;
    }
  }

  .addy {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;

    text-align: center;

    margin-bottom: 20px;

    @media (max-width: 1300px) {
      font-size: 11px;
      margin-bottom: 5px;
    }
  }
`;

export default function CurrentShop({ currCity, currAddy }) {
  return (
    <CurrentShopStyle>
      <div className="label">SHOPPING AT</div>
      <div className="city">{currCity}</div>
      <div className="addy">{currAddy}</div>
    </CurrentShopStyle>
  );
}
