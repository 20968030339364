/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import './src/styles/bootstrap-grid/bootstrap-grid.min.css';


import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import { SearchProvider, WithSearch } from '@elastic/react-search-ui';
import Cookies from 'universal-cookie';
import Layout from './src/components/Layout/Layout';

import { DestinationProvider } from './src/contexts/DestinationContext';
import { ModalsProvider } from './src/contexts/ModalsContext';
import { ProductPromoProvider } from './src/contexts/ProductPromoContext';


// ElasticSearch
import searchQuery from './src/utils/searchQuery';

// Promo Popup
import useLocalStorage from './src/hooks/useLocalStorage';

const connector = new AppSearchAPIConnector({
  searchKey: process.env.GATSBY_ES_SECRET_KEY,
  engineName: process.env.GATSBY_ES_ENGINE_NAME,
  endpointBase: process.env.GATSBY_ES_BASE_URL,
  cacheResponses: true,
});

const Wrapper = ({ element, props }) => {
  const [cityName, setCityName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const value = { cityName, setCityName };
  const modalsValue = { isModalOpen, setIsModalOpen };

  // ProductPromoContext
  // Fixed Values
  const prodLimit = 3;
  const promoIds = [
    '61f5a3ef350604000c82c675',
    '61d639ebc750cc000c2f2d24',
    '61cf36c5adcf43000ce7b5ea',
  ];
  // States
  const [isPPModalOpen, setIsPPModalOpen] = useState(false);
  // Cookies and LocalStorage
  const [preRollCount, setPreRollCount] = useLocalStorage('pre-roll-count', 0);
  const [popupCount, setPopupCount] = useLocalStorage('promo-popup-count', 0);
  const cookies = new Cookies();
  const promoTimmer = cookies.get('promoTimmer');

  useEffect(() => {
    if (promoTimmer) {
      console.log('sorry need to wait..');
    } else {
      setPreRollCount(0);
      const date = new Date();
      date.setTime(Date.now() + 1000 * 60 * 60 * 12 * 1);
      cookies.set('promoTimmer', true, { path: '/', expires: date });
    }
  }, []);

  // Functions
  const increaseProductCount = (amount, id) => {
    console.log('increaseProductCount - hit, id', id);
    if (promoIds.includes(id)) {
      console.log('increaseProductCount - id in promoIds');
      setPreRollCount(Math.abs(preRollCount + amount));
    }
  };
  const decreaseProductCount = (amount, id) => {
    console.log('decreaseProductCount - hit, id', id);
    if (promoIds.includes(id)) {
      console.log('decreaseProductCount - id in promoIds');
      const result = preRollCount - amount < 0 ? 0 : preRollCount - amount;
      setPreRollCount(result);
    }
  };
  const isInLimit = (id) => {
    if (promoIds.includes(id)) {
      console.log(
        'isInLimit product good, preRollCount < prodLimit',
        preRollCount < prodLimit
      );
      return preRollCount < prodLimit;
    }
    return true;
  };
  const isInPromoLimitProducts = (id) => promoIds.includes(id);
  const remainingLimit = () => {
    const result = prodLimit - preRollCount;
    if (result < 0) return 0;
    return result;
  };
  const removeLimit = (id) => {
    if (promoIds.includes(id)) {
      setPreRollCount(0);
    }
  };
  const productPopupValue = {
    prodLimit,
    isPPModalOpen,
    setIsPPModalOpen,
    popupCount,
    setPopupCount,
    preRollCount,
    setPreRollCount,
    increaseProductCount,
    decreaseProductCount,
    isInLimit,
    isInPromoLimitProducts,
    remainingLimit,
    removeLimit,
  };



  // const { currentRetailer } = useDutchieCheckout();

  return (
    // <SearchProvider
    //   config={{
    //     apiConnector: connector,
    //     searchQuery,
    //     trackUrlState: false,
    //     alwaysSearchOnInitialLoad: true,
    //     initialState: {
    //       filters: [
    //         { field: 'store_id', values: [currentRetailer], type: 'all' },
    //       ],
    //     },
    //   }}
    // >
    <DestinationProvider value={value}>
      <ModalsProvider value={modalsValue}>
        <ProductPromoProvider value={productPopupValue}>
          <Layout {...props}>{element}</Layout>
        </ProductPromoProvider>
      </ModalsProvider>
    </DestinationProvider>
    /* </SearchProvider> */
  );
};

export default Wrapper;
