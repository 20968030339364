import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';


const MOBILE_BREAKPOINT = "768px";

const Container = styled.footer`
    padding: 0 50px;
    font-family: 'Oswald';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    background: #F5F5F5;

    @media screen and (max-width: 992px) {
        font-size: 20px;
        line-height: 36px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 7rem;
    padding: 50px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.4);

    a {
        display: block;
        font-weight: 400;
    }

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        text-align: center;
    }
`;

const Title = styled.h5`
    font-size: inherit;
    font-weight: 600;
`;

const Copyright = styled.div`
    padding: 40px 0;
    font-weight: 300;
    white-space: pre;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        text-align: center;
    }

    @media screen and (max-width: 576px) {
        padding-top: 0;
        
        & > * {
            display: block;
        }

        .divider {
            display: none;
        }
    }
`;

export default function Footer(){
    return(
        <Container>
            <Content>
                <div>
                    <Title>Get in Touch</Title>
                    <a href="mailto:info@houseofcannabis.ca">info@houseofcannabis.ca</a>
                    <a href="tel:416-591-8819">416-591-8819</a>
                </div>
                <div>
                    <Title>Company</Title>
                    <Link to="/stores">Stores</Link>
                    <a target='_blank'  href='https://thehouseofcannabis.wm.store/'>Weedmaps</a>
                    {/* <Link to="">About Us</Link> */}
                    {/* <Link to="">Careers</Link> */}
                </div>
                <div>
                    <Title>Information</Title>
                    {/* <Link to="">Community</Link>
                    <Link to="">Reviews</Link> */}
                    <Link to="/blog">Blog</Link>
                </div>
                <div>
                    <Title>Help</Title>
                    <Link to="/near-me">Dispensary Near me</Link>
                    {/* <Link to="">Support</Link> */}
                </div>
                <div>
                    <Title>Follow Us</Title>
                    {/* <a href="#">Facebook</a> */}
                    <a href="https://twitter.com/thehousecan?lang=en">Twitter</a>
                    <a href="https://www.instagram.com/thehousecann/">Instagram</a>
                </div>
            </Content>
            <Copyright>
                <Link to="/privacy">Privacy Policy</Link>
                <span className="divider">   |   </span>
                <Link to="/termsofservice">Terms of Service</Link>
                <span className="divider">   |   </span>
                <span>© 2021 House of Cannabis</span>
            </Copyright>
        </Container>
    );
}
