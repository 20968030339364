export const filterRetailers = (retailers, labelKey) => {
  const rs = retailers.filter((r)=>_containsFilter(r, labelKey)).map((r) => {
    const _r = { ...r };
    const newName = _determineNewName(r[labelKey].toLowerCase().trim());
    console.log(r[labelKey].toLowerCase().trim());
    _r[labelKey] = newName;
    return _r;
  });
  return rs;
};

const _determineNewName = (name) => {
  if (name.includes(_whiteList[0])) return ' Keswick - York Region';
  if (name.includes(_whiteList[1])) return ' Barrie, Orillia, Innisfil';
};

const _whiteList = ['keswick', 'barrie'];

const _containsFilter = (r, labelKey) => _whiteList.some((w) => r[labelKey].toLowerCase().trim().includes(w));
