/* eslint-disable array-callback-return */
import React from 'react';

const surfImpression = (product, option, price, quantity) => {
  surf(
    'addImpression', // Provide product details in an impressionFieldObject.
    product.id, // Product ID
    product.name, // Product name
    '', // Product list
    product.brand_name, // Product brand
    `${product.category}/${product.subcategory}`, // Product category
    option, // Product variant
    price, // Product price
    quantity, // Product quantity
    0, // Product position
    'cad' // Product currency
  );
};

// Q-SF: How to communicate an increase in quantity? addProduct.quantity at 1 or at new total?
// either or
// Q-SF: Why am i not seeing thing happen on surfside backend?
// Q-SF: invalid domian?

const surfaddProduct = (product, option, price, quantity) => {
  surf(
    'addProduct',
    product.id, // Product ID
    product.name, // Product name
    '', // Product list
    product.brand_name, // Product brand
    `${product.category}/${product.subcategory}`, // Product category
    option, // Product variant
    price, // Product price
    quantity, // Product quantity
    '', // Product coupon
    0, // Product position
    'cad' // Product currency
  );
};

const surfaddTransaction = (storename, checkout, step = 0) => {
  surf(
    'addTransaction', // Provide Transaction details in an transactionFieldObject.
    'O-0001', // Transaction ID (string). //Q-SF: What is this value, can I make it the checkout.id? checkout.id
    storename, // Transaction affiliation (string).
    checkout.priceSummary.total, // Transaction revenue (string).  //Q-SF: string or number? //both are good
    checkout.priceSummary.taxes, // Product tax (string).          //Q-SF: string or number?
    0.0, // Transaction shipping (string). //Q-SF: string or number?
    '', // Transaction coupon (number).
    '', // Transaction list (string).
    step, // Transaction step (number). //Q-SF: What is this value? What is this value? //ok to be 1 or empty
    '', // Transaction option (string).
    'cad' // Transaction currency (string).
  );
};





const addToCartSurf = (product, option, quantity = 1) => {
  const selectedOptionObj = product.variants.filter(
    (x) => x.option === option
  )[0];
  console.log(product);
  console.log('option: ', option);
  console.log('selectedOptionObj: ', selectedOptionObj);
  const price = selectedOptionObj.specialPriceRec
    ? selectedOptionObj.specialPriceRec
    : selectedOptionObj.priceRec;
  console.log('[Surf DEBUG 🌊]: Adding Product to Cart...', product.name);
  surfImpression(product, option, price, quantity);
  surfaddProduct(product, option, price, quantity);
  surf('setCommerceAction', 'add');
  console.log(
    "[Surf DEBUG 🌊]: DONE addImpression, addProduct, surf('setCommerceAction', 'add')"
  );
};

const removeFromCartSurf = (product, option, quantity = 1) => {
  const selectedOptionObj = product.variants.filter(
    (x) => x.option === option
  )[0];
  const price = selectedOptionObj.specialPriceRec
    ? selectedOptionObj.specialPriceRec
    : selectedOptionObj.priceRec;
  console.log('[Surf DEBUG 🌊]: Removing from Cart...', product.name);
  surfImpression(product, option, price, quantity);
  surfaddProduct(product, option, price, quantity);
  surf('setCommerceAction', 'remove');
  console.log(
    "[Surf DEBUG 🌊]: DONE addImpression, addProduct, surf('setCommerceAction', 'remove')"
  );
};

const viewProducttSurf = (product, option, quantity = 1) => {
  const selectedOptionObj = product.variants.filter(
    (x) => x.option === option
  )[0];
  const price = selectedOptionObj.specialPriceRec
    ? selectedOptionObj.specialPriceRec
    : selectedOptionObj.priceRec;
  console.log('[Surf DEBUG 🌊]: View Item...', product.name);
  surfImpression(product, option, price, quantity);
  surfaddProduct(product, option, price, quantity);
  surf('setCommerceAction', 'view');
  console.log(
    "[Surf DEBUG 🌊]: DONE addImpression, addProduct, surf('setCommerceAction', 'view')"
  );
};

const clickProducttSurf = (product, option, quantity = 1) => {
  const selectedOptionObj = product.variants.filter(
    (x) => x.option === option
  )[0];
  const price = selectedOptionObj.specialPriceRec
    ? selectedOptionObj.specialPriceRec
    : selectedOptionObj.priceRec;
  surfImpression(product, price, quantity);
  surfaddProduct(product, price, quantity);
  surf('setCommerceAction', 'click');
  console.log(
    "[Surf DEBUG 🌊]: DONE addImpression, addProduct, surf('setCommerceAction', 'click')"
  );
};

const checkoutSurf = (storename, checkout) => {
  surfaddTransaction(storename, checkout);

  checkout?.items.map((item) => {
    const price = item.product.specialPriceRec
      ? item.product.specialPriceRec
      : item.product.priceRec;
    const { quantity } = item;
    surfaddProduct(item.product, price, quantity);
  });

  surf('setCommerceAction', 'checkout');
  console.log(
    "[Surf DEBUG 🌊]: surf('addProduct')*numprod, surf('addTransaction'), surf('setCommerceAction', 'checkout')"
  );
};

const purchaseSurf = (storename, checkout) => {
  surfaddTransaction(storename, checkout);

  checkout?.items.map((item) => {
    const price = item.product.specialPriceRec
      ? item.product.specialPriceRec
      : item.product.priceRec;
    const { quantity } = item;
    surfaddProduct(item.product, price, quantity);
  });

  surf('setCommerceAction', 'purchase');
  console.log(
    "[Surf DEBUG 🌊]: surf('addProduct')*numprod, surf('addTransaction'), surf('setCommerceAction', 'purchase')"
  );
};

export {
  addToCartSurf,
  removeFromCartSurf,
  checkoutSurf,
  clickProducttSurf,
  purchaseSurf,
  viewProducttSurf,
};
