import React, { useEffect, useContext } from 'react';
import 'normalize.css';
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import GlobalStyles from '../../styles/GlobalStyles';
import Typography from '../../styles/Typography';
import Navbar from '../Nav/Navbar';
import Footer from './Footer';
import ModalsContext from '../../contexts/ModalsContext';
import SubscribePopup from './SubscribePopup';
import { TotalPopup2 } from '../Popup/TotalPopup/TotalPopup2';

export default function Layout({ children }) {
  const cookies = new Cookies();
  const { setIsModalOpen } = useContext(ModalsContext);
  const dailyPopup = cookies.get('dailyPopup');
  useEffect(() => {
    if (!dailyPopup) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, 10000);
    }
  }, []);

  return (
    <>
      {/* <Helmet>
        <script src="https://platform.heed.chat/heed/iframe.js" />
      </Helmet>
      <heed-main
        store-view="all"
        color="#f4d29a"
        open="true"
        id="heed-dom"
        retailer="5f11d8265a9ade011a13175a"
      /> */}
      <GlobalStyles />
      <Typography />

      <SubscribePopup />

      <Navbar />

      <TotalPopup2 />

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {children}
      <Footer />
    </>
  );
}
