import React, { useEffect, createRef, useContext } from 'react';
import styled from 'styled-components';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { StaticQuery, graphql, useStaticQuery } from 'gatsby';
import { CheckoutContext } from '../apolloMenu/contexts/checkout';
import ProductList from './ProductList';
import TotalCostList from './TotalCostList';
import CurrentShop from './CurrentShop';
import CheckoutBtn from './CheckoutBtn';

const MOBILE_WIDTH = `900px`;
const LAPTOP_WIDTH = '1300px';

const SideMenu = styled.div`
  position: fixed;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  top: 55px;
  right: -100%;
  width: 35%;
  height: calc(100% - 55px);

  opacity: 0;

  transition: all 0.5s ease;

  padding-top: 34px;
  padding-left: 30px;
  padding-right: 50px;
  padding-bottom: 34px;

  background: #e5e5e5;

  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &.is-open {
    right: 0;
    opacity: 1;

    min-width: 35%;

    transition: all 0.5s ease;
  }

  h2 {
    font-family: Oswald;
    color: black;
    font-size: 30px;
    text-align: center;
  }

  @media (max-width: ${LAPTOP_WIDTH}) {
    padding-top: 14px;
    padding-left: 20px;
    padding-right: 30px;
    padding-bottom: 14px;
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    width: 100%;
  }
`;

const Background = styled.div`
  position: fixed;
  z-index: -1;

  background-color: black;
  opacity: 0;

  transition: all 0.5s ease;

  width: 100%;
  height: calc(100% - 80px);
  right: 0;

  &.is-open {
    z-index: 6;
    opacity: 0.4;
    transition: all 0.5s ease;
  }
`;

const ProductListWrapper = styled.div`
  width: 100%;

  order: 1;
  @media (max-width: ${MOBILE_WIDTH}) {
    order: 2;
  }
`;

const TotalCostWrapper = styled.div`
  margin: 35px 0px;
  margin-left: 24px;
  width: 100%;

  @media (max-width: ${LAPTOP_WIDTH}) {
    margin: 10px 0px;
  }

  order: 2;
`;

const ButtonWrapper = styled.div`
  width: 100%;

  order: 3;
`;

const CurrentShopWraper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  width: 100%;

  order: 4;

  @media (max-width: ${MOBILE_WIDTH}) {
    flex-grow: 0;
    order: 1;
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

export default function SideCart({ toggle, setToggle }) {
  const sideCartRef = createRef();
  const { checkout, retailerList, currentRetailer } =
    useContext(CheckoutContext);

  useEffect(() => {
    if (toggle) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      disableBodyScroll(sideCartRef.current);
    }
  }, [toggle]);

  // enable scroll
  useEffect(() => {
    if (!toggle) {
      enableBodyScroll(sideCartRef.current);
      document.getElementsByTagName('html')[0].style = '';
    }
  }, [toggle]);

  const onClickOffCart = (e) => {
    e.preventDefault();
    setToggle(false);
  };
  const data = useStaticQuery(graphql`
    query {
      strapiDispensaryIds {
        Store {
          DispensaryId
          Name
          RetailerId
          id
          CheckoutUrl
        }
      }
    }
  `);
  const checkouturl = `${
    data.strapiDispensaryIds.Store.filter(
      (x) => x.RetailerId === currentRetailer
    )[0]?.CheckoutUrl + checkout?.id
  }/`;
  const redirectUrl = `https://thehousecannabis.ca/?checkout_id=${checkout?.id}`;
  const finalurl = `https://${checkouturl}?r=${redirectUrl}`;
  const retailerObj = retailerList?.filter((x) => x.id === currentRetailer)[0];
  return (
    <>
      <Background
        className={toggle ? 'is-open' : ''}
        onClick={onClickOffCart}
      />
      <SideMenu className={toggle ? 'is-open' : ''} ref={sideCartRef}>
        <h2>Cart</h2>
        <ProductListWrapper>
          {typeof window !== 'undefined' && <ProductList />}
        </ProductListWrapper>
        <>
          <TotalCostWrapper>
            <TotalCostList
              subtotal={
                checkout && checkout.priceSummary
                  ? checkout.priceSummary.subtotal / 100.0
                  : 0
              }
              taxes={
                checkout && checkout.priceSummary
                  ? checkout.priceSummary.taxes / 100.0
                  : 0
              }
              total={
                checkout && checkout.priceSummary
                  ? checkout.priceSummary.total / 100.0
                  : 0
              }
              discounts={
                checkout && checkout.priceSummary
                  ? checkout.priceSummary.discounts / 100.0
                  : 0
              }
            />
          </TotalCostWrapper>
          <ButtonWrapper>
            <CheckoutBtn
              setToggle={setToggle}
              finalurl={finalurl}
              storeName={retailerObj?.name.slice(24)}
              checkout={checkout}
            />
          </ButtonWrapper>
        </>
        {retailerList && currentRetailer ? (
          <CurrentShopWraper>
            <CurrentShop
              currCity={retailerObj?.name?.slice(24)}
              currAddy={retailerObj?.address}
            />
          </CurrentShopWraper>
        ) : (
          <CurrentShopWraper>
            <CurrentShop currCity="..." currAddy="..." />
          </CurrentShopWraper>
        )}
      </SideMenu>
    </>
  );
}
