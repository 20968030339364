import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiTruck } from 'react-icons/fi';
import LocationDropdown from './LocationDropdown';
import { focusAnimation } from './focusAnimation';
import { useRatailers } from '../../../hooks/useRetailersId';
import { UseGeolocation } from '../../../hooks/useGeolocation';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 21px;
  button {
    width: 217px;
    color: var(--lightgreen);
    height: 60px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Oswald';
    background: white;
    border: 1px solid var(--lightgreen);
    &:disabled {
      color: gray;
      background: var(--bggray);
      border-color: var(--bggray);
      &:hover {
        color: gray;
        background: var(--bggray);
        border-color: var(--bggray);
        cursor: default;
      }
    }
    &:hover {
      cursor: pointer;
      color: white;
      background: var(--lightgreen);
    }
  }
  @media (max-width: 1000px) {
    margin-top: 0px;
    margin-bottom: 82px;
  }
`;

const Other = styled.div`
  transition: 300ms;
  transform: ${({ animatedShowLocation }) =>
    animatedShowLocation ? ' translateX(0%)' : 'translateX(100%)'};
  flex-shrink: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 50px;
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--darkgreen);
    margin-top: 63px;
    h2 {
      color: black;
      font-family: 'Oswald';
      font-weight: 600;
      font-size: 50px;
      margin-bottom: 20px;
    }
  }
  .addressBar {
    width: 281px;
    height: 53px;
    background: ${(props) =>
      props.gray ? 'var(--bggray)' : 'var(--lightgreen)'};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 95.29px;
    padding-right: 30px;
    padding-left: 30px;
    .icon {
      color: white;
      font-size: 50px;
      margin-right: 7px;
      &:hover {
        cursor: pointer;
      }
    }
    .addressBar {
      font-size: 14px;
      border: none;
      border-bottom: 1px solid white;
      height: 50%;
      color: white;
      border-radius: 0px;
      padding: 0;
      ::placeholder {
        color: white;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .warning {
    font-size: 15px;
    text-align: center;
    font-family: 'Oswald';
    color: #2d2b2c;
    opacity: 50%;
    text-transform: uppercase;
    margin-top: 24px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    .text {
      margin-top: 10px;
      font-size: 25px;
      h2 {
        font-size: 20px;
      }
      .addressBar {
        font-size: 12px;
      }
    }
    .warning {
      font-size: 12px;
      margin-top: 22px;
    }
  }
`;
const AnimatedButton = styled.button`
  animation-name: ${({ animated }) => (animated ? focusAnimation : '')};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

export const ADDRESS_STATE = {
  unset: 1,
  notAtList: 2,
  atList: 3,
};

const Location = ({ animatedShowLocation, setOpen, setCurrentRetailer }) => {
  const [city, setCity] = useState();
  const [addressState, setAddressState] = useState(ADDRESS_STATE.unset);
  const { sortedOptionsList, cityToId } = useRatailers();
  useEffect(() => {
    if (city) {
      setAddressState(ADDRESS_STATE.atList);
    }
  }, [city]);
  const onHandleSubmit = () => {
    if (addressState === ADDRESS_STATE.atList) {
      window.localStorage.setItem('location', city);
      setOpen(false);
      setCurrentRetailer(
        cityToId(city)?.value || '0aae4e48-dfef-49c6-b0f1-ce9d43bc23ba'
      );
    }
  };
  UseGeolocation({
    setCityName: setCity,
    setCurrentRetailer,
  });
  return (
    <Other animatedShowLocation={animatedShowLocation}>
      <div className="text">
        <FiTruck />
        <h2>we deliver!</h2>
      </div>
      <LocationDropdown
        hide={addressState === ADDRESS_STATE.atList}
        city={city}
        locations={sortedOptionsList}
        animated={addressState === ADDRESS_STATE.notAtList}
        setCity={setCity}
      />
      <p className="warning">
        enter your address for same <br />
        day delivery from the house{' '}
      </p>

      <ButtonWrapper>
        <AnimatedButton
          onClick={onHandleSubmit}
          animated={addressState === ADDRESS_STATE.atList}
          disabled={addressState !== ADDRESS_STATE.atList}
        >
          ENTER THE HOUSE
        </AnimatedButton>
      </ButtonWrapper>
    </Other>
  );
};

export default Location;
