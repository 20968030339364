module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K87ZBWZ","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://plus.dutchie.com/plus/2021-07/graphql","headers":{"Authorization":"Bearer secret-eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJBUEktQ0xJRU5UIiwiZXhwIjozMzE4NjY0MjAwOSwiaWF0IjoxNjI5NzMzMjA5LCJpc3MiOiJodHRwczovL2R1dGNoaWUuY29tIiwianRpIjoiNzgwNDdkMjctYjY1OC00ZmM5LWFjNzQtMWJhYmQ2YzYzNzE3IiwiZW50ZXJwcmlzZV9pZCI6Ijk0OWJmM2RiLTM4M2EtNDc3MC04YWU5LWQzM2JlOTQxYzZkMiIsInV1aWQiOiI0NWU1MTdiZS0yZjRlLTQ5OWMtYWM2ZC05YjE3YTZiNzAwNzMifQ.lAiXT8-jI3dBZ4krt99cg5LkuHfixRBoJba84fOOauA"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Lato","Oswald"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
