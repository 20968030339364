import React, { useEffect } from 'react';
import { getClosestCity } from '../helpers/getLocation';

export const UseGeolocation = ({
  setCityName,
  setError = () => {},
  setCurrentRetailer = () => {},
}) => {
  useEffect(() => {
    const previousLocation = window.localStorage.getItem('location');
    if (!previousLocation) {
      navigator.geolocation.getCurrentPosition(
        (currentLocation) => {
          const currentCoords = currentLocation.coords;
          const closestCity = getClosestCity(
            currentCoords.latitude,
            currentCoords.longitude
          );
          window.localStorage.setItem('location', closestCity);
          setCityName(closestCity);
          setCurrentRetailer(closestCity);
        },
        (locationError) => setError(),
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setCityName(previousLocation);
    }
  }, []);
};
