import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Img from 'react-cool-img';
import { CheckoutContext } from '../apolloMenu/contexts/checkout';
import QuantityWidget from './QuantityWidget';
import exitX from '../../assets/images/x.svg';

import ProductPromoContext from '../../contexts/ProductPromoContext';
import { removeFromCartSurf } from '../../hooks/useSurfside';

const MOBILE_WIDTH = '900px';

const CartProductStyle = styled.div`
  display: none;
  @media (max-width: ${MOBILE_WIDTH}) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 10px 0px;

    min-height: 114px;

    width: 100%;
  }
`;

const ItemCard = styled.div`
  background-color: white;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  padding: 10px 0px;
  max-height: 80px;

  border-radius: 3px;

  .prodImage {
    grid-column: 1;

    margin-left: 14px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4px;

    .img {
      object-fit: contain;
    }
  }

  .name {
    grid-column: 2;

    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close {
    grid-column: 3;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-right: 30px;
  }
`;

const LowerItemCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  .cardbox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .quantity {
  }

  .price {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: var(--darkgreen);
    width: 25%;
  }

  .cardlabel {
    color: black;

    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
  }

  .cardvalue {
    display: flex;
    height: 45px;
    align-items: center;
  }
`;

export default function MobileCartProduct({ item }) {
  console.log('ITEM', item);
  const [quantityState, setQuantityState] = useState(item.quantity);
  const {
    prodLimit,
    removeLimit,
    remainingLimit,
    limit,
    isInLimit,
    isInPromoLimitProducts,
    PromoLimitProducts,
    increaseProductCount,
    decreaseProductCount,
  } = useContext(ProductPromoContext);

  return (
    <CartProductStyle>
      <CheckoutContext.Consumer>
        {({ updateQuantity, removeFromCart }) => (
          <>
            <ItemCard>
              <div className="prodImage">
                <Img src={item.product.image} classNamae="img" />
              </div>
              <div className="name">{item.product.name}</div>
              <div
                className="close"
                onClick={() => {
                  removeFromCart(item.id);
                  removeLimit(item.product.id);
                  removeFromCartSurf(item.product, item.option, item.quantity);
                }}
              >
                <img src={exitX} />
              </div>
            </ItemCard>

            <LowerItemCard>
              <div className="quantity cardbox">
                <div className="cardlabel">QUANTITY</div>
                <div className="cardvalue">
                  <QuantityWidget
                    quantity={item.quantity}
                    setQuantity={(value) => {
                      updateQuantity(item.id, value);
                    }}
                    qlimit={
                      isInPromoLimitProducts(item.product.id)
                        ? remainingLimit()
                        : 9
                    }
                    fromCart
                    id={item.product.id}
                    oglimit={prodLimit}
                    product={item.product}
                    option={item.option}
                  />
                </div>
              </div>

              <div className="price cardbox">
                <div className="cardlabel">PRICE</div>
                <div className="cardvalue">
                  {item.product.variants.length == 1
                    ? `$${(
                        item.product.variants[0].priceRec * item.quantity
                      ).toFixed(2)}`
                    : `$${(
                        item.product.variants[
                          item.product.variants
                            .map((v) => v.option)
                            .indexOf(item.option)
                        ].priceRec * item.quantity
                      ).toFixed(2)}`}
                </div>
              </div>
            </LowerItemCard>
          </>
        )}
      </CheckoutContext.Consumer>
    </CartProductStyle>
  );
}
