import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import Select from 'react-select';

import SwiperCore, { Navigation, Pagination } from 'swiper';

// For Saving To Cookies
import Cookies from 'universal-cookie';
import { focusAnimation } from './focusAnimation';

SwiperCore.use([Pagination, Navigation]);

const Wrapper = styled.div`
  animation-name: ${({ animated }) => (animated ? focusAnimation : '')};
  &:hover {
    animation-name: '';
  }
  animation-duration: 2s;
  animation-iteration-count: infinite;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  .react-select__placeholder {
    @media (max-width: 1000px) {
      font-size: 14px;
    }
  }
  .react-select__control {
    background-color: ${({ hide }) => (hide ? '#ddd' : '')};
    @media (max-width: 1000px) {
      width: 268px;
      max-height: 51px;
    }
  }
  .react-select__single-value {
    color: ${({ hide }) => (hide ? '#fff' : '')};
  }
  .react-select__value-container {
    display: flex;
    align-items: center;
    height: 100%;
    /* justify-content: center; */
  }
  display: flex;

  @media (max-width: 1000px) {
    transition: all ease-out 500ms;
    border-radius: 8px;
    padding: 15px 10px;
    margin-top: 0;
  }
`;

const selectStyle = {
  // actual selector
  control: (provided, state) => ({
    ...provided,
    width: '70vw',
    maxWidth: '268px',
    height: '51px',
    color: 'white',
    background: state.hide ? 'var(--gray)' : '#F8FCF1',
    borderColor: state.hide ? 'var(--bggray)' : 'var(--darkgreen)',
    borderRadius: '0px',
  }),

  // individual option in dropdown
  option: (provided, state) => ({
    ...provided,
    color: 'white',
    fontFamily: 'Oswald',
    fontSize: '20px',
  }),

  placeholder: (provided, state) => ({
    ...provided,
    color: state.hide ? 'var(--bggray)' : 'var(--darkgreen)',
    fontFamily: 'Oswald',
    fontSize: '17px',
  }),

  // the dropdown menu (once expanded)
  menu: (provided, state) => ({
    ...provided,
    width: '70vw',
    maxWidth: '268px',
    color: 'white',
    background: 'linear-gradient(287.58deg, #A1C654 20.01%, #B0E640 91.64%)',
    fontFamily: 'Oswald',
  }),

  // chosen value
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--darkgreen)',
    height: 20,
    fontFamily: 'Oswald',
    fontSize: '20px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.hide ? 'var(--bggray)' : '#000000',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

export default function LocationDropdown({
  animated,
  hide,
  city,
  setCity,
  locations = [],
}) {
  return (
    <Wrapper hide={hide} animated={animated}>
      <Select
        onChange={(c) => {
          setCity(c.label);
        }}
        value={city && { label: city }}
        options={locations}
        styles={selectStyle}
        placeholder="CHOOSE YOUR LOCATION"
        classNamePrefix="react-select"
        isSearchable={false}
      />
    </Wrapper>
  );
}
