/* eslint-disable react/button-has-type */
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { CheckoutContext } from '../../apolloMenu/contexts/checkout';
import AgeCheck from './AgeCheck';

import Location from './Location';

export const ADULT_STATE = {
  unset: 1,
  notAdult: 2,
  adult: 3,
};

const Wrapper = styled.div`
  position: fixed;
  display: ${({ open }) => (open ? 'inline-block' : 'none')};
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  h1 {
    margin-bottom: 5vh;
    color: var(--darkgreen);
  }
`;
const Inner = styled.div`
  &::-webkit-scrollbar {
    width: 1px;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  overflow: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3vh 1vh;
  min-height: 80%;
  @media (max-width: 1000px) {
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    border-radius: 0px;
    padding: 0px;
  }
`;
const AgeGate = styled.div`
  width: 100%;
  transition: 0.3s;
  transform: ${({ adultState }) =>
    adultState === ADULT_STATE.adult ? 'translateX(-100%)' : ' '};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  button {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    height: 40px;
    width: 217px;
    font-family: 'Oswald';
    color: var(--darkgreen);
    border: 1px solid var(--darkgreen);
    &:disabled {
      color: gray;
      border-color: var(--bggray);
      background: var(--bggray);
      &:hover {
        cursor: default;
        color: gray;
        border-color: var(--bggray);
        background: var(--bggray);
      }
    }
    &:hover {
      cursor: pointer;
      color: white;
      background: var(--lightgreen);
    }
  }
  @media (max-width: 1000px) {
    border: none;
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;

export const TotalPopup2 = () => {
  const { setCurrentRetailer, currentRetailer } = useContext(CheckoutContext);
  const [open, setOpen] = useState(false);
  const [isNineteen, setIsNineteen] = useState(null);
  const [adultState, setAdult] = useState(ADULT_STATE.unset);
  const [animatedShowLocation, setShowLocation] = useState(false);
  useEffect(() => {
    const savedIsAnAdult = window.localStorage.getItem('adult');
    const locationInStorage = window.localStorage.getItem('location');
    if (locationInStorage && currentRetailer && savedIsAnAdult) {
      setOpen(false);
    } else {
      setOpen(true);
      if (savedIsAnAdult) {
        setAdult(ADULT_STATE.adult);
        setShowLocation(true);
      }
    }
  }, []);
  const onHandleAgeSubmit = () => {
    if (!isNineteen) {
      setAdult(ADULT_STATE.notAdult);
    } else {
      setAdult(ADULT_STATE.adult);
      window.localStorage.setItem('adult', 'true');
      setTimeout(() => {
        setShowLocation(true);
      }, 300);
    }
  };

  return (
    <>
      {open && (
        <Wrapper open={open}>
          <Inner>
            {!animatedShowLocation && (
              <AgeGate adultState={adultState}>
                <AgeCheck
                  isNineteen={isNineteen}
                  setIsNineteen={setIsNineteen}
                  adultState={adultState}
                  setAdult={setAdult}
                />
                {adultState !== ADULT_STATE.notAdult && (
                  <button onClick={onHandleAgeSubmit} disabled={isNineteen===null}>
                    SUBMIT
                  </button>
                )}
              </AgeGate>
            )}
            {adultState === ADULT_STATE.adult && (
              <Location
                setOpen={setOpen}
                animatedShowLocation={animatedShowLocation}
                setCurrentRetailer={setCurrentRetailer}
              />
            )}
          </Inner>
        </Wrapper>
      )}
    </>
  );
};
