/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { FiMenu, FiX } from 'react-icons/fi';
import { BsChevronLeft } from 'react-icons/bs';
import { WithSearch } from '@elastic/react-search-ui';
import { CheckoutContext } from '../apolloMenu/contexts/checkout';
import ModalsContext from '../../contexts/ModalsContext';

import DropdownStores from './DropdownStores';
import DropdownDelivery from './DropdownDelivery';
import logo from '../../assets/images/logo.svg';
import Cart from '../../assets/images/ShoppingCart2.svg';
import logoMobile from '../../assets/images/logo-1.png';
import SideCart from '../Cart/SideCart';

import SlidingBanner from '../Other/SlidingBanner';
import MiddleBars from './MiddleBars';

import LocationModal from '../Modal/LocationModal';
import MobileLocation from './MobileLocation';
import { useDutchieLocation } from '../../hooks/useDutchieLocation';
import { usePromobar } from '../../hooks/usePromobar';

const MOBILE_BREAKPOINT = '960px';

const Nav = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 130px;
  background-color: white;

  flex-wrap: wrap;

  .first-element {
    grid-column-start: 2;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 10px;
  }
`;

const LogoLink = styled(Link)`
  justify-self: start;
  margin-left: 20px;
  font-size: 2rem;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

const LogoLinkMobile = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: none;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    margin-left: 5px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    height: 45%;
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: 960px) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    transform: translate(-50%, 60%);
    display: block;
    font-size: 35px;
    color: var(--darkgreen);
    cursor: pointer;
  }
`;

const NavUL = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  width: 70vw;
  margin: 0;
  margin-right: 2rem;
  list-style: none;
  text-align: center;

  &.desktop {
    display: grid;
  }

  &.mobile {
    display: none;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    &.mobile {
      display: flex;
    }

    &.desktop {
      display: none;
    }

    position: absolute;
    top: 80px;
    left: -100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 90vh;
    padding: 0px;
    opacity: 1;
    transition: all 0.5s ease;

    &.is-open {
      left: 0;
      z-index: 1;
      background: white;
      opacity: 1;
      transition: all 0.5s ease;
    }
  }
`;

const NavLI = styled.li`
  display: block;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover {
    display: block;
    color: var(--lightgreen);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: table;
    width: 100%;
    padding: 2rem;
    text-align: center;

    &:hover {
      text-decoration: none;
      border-radius: 0;
    }

    .nav-link--mobile-disabled {
      pointer-events: none;
    }
  }
`;

const CTAButton = styled.div`
  button {
    color: black;

    &:hover {
      color: white;
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

const NavGroup = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    height: 45%;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

const CartButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  position: relative;
  .amount {
    margin-left: 5px;
    font-family: Oswald;
    font-weight: 500;
    color: black;
    font-size: 17px;
  }
  font-size: 30px;
  margin-left: 30px;
  margin-right: 20px;
  padding-bottom: 7px;

  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    background: var(--lightgreen);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  &:hover:after {
    width: 100%;
    left: 0;
  }
  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-right: 55px;
    margin-left: 0;
    padding-bottom: 2px;
    //margin-bottom: 16px;
    align-self: center;
  }
`;

export default function Navbar() {
  const [selectedDropdown, setSelectedDropdown] = useState('');
  const [cartToggle, setCartToggle] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { setIsModalOpen } = useContext(ModalsContext);
  const [locationmodal, setLocationModal] = useState(false);
  const [stagedlocation, setStagedLocation] = useState(null);
  const handleOnMouseEnter = (dropdownLabel) => {
    if (window !== undefined) {
      window.innerWidth > 960 && setSelectedDropdown(dropdownLabel);
    }
  };

  const handleNavLIClick = (dropdownLabel) => {
    if (window !== undefined) {
      window.innerWidth < 960 && setSelectedDropdown(dropdownLabel);
    }
  };
  const promoText = usePromobar();

  const { checkout, setCurrentRetailer } = useContext(CheckoutContext);
  return (
    <>
      <LocationModal
        modalOn={locationmodal}
        setModalOn={setLocationModal}
        setCurrentRetailer={setCurrentRetailer}
        option={stagedlocation}
      />

      <SlidingBanner
        message={promoText}
        showMobile={false}
      />

      <Nav onMouseLeave={() => setSelectedDropdown('')}>
        <LogoLink to="/">
          <img src={logo} alt="The House of Cannabis" />
        </LogoLink>

        <LogoLinkMobile>
          <Link to="/">
            {!selectedDropdown && (
              <img src={logoMobile} alt="The House of Cannabis" />
            )}
          </Link>
        </LogoLinkMobile>

        <MenuIcon
          onClick={() => {
            setSelectedDropdown('');
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          {isMenuOpen ? <FiX /> : <FiMenu />}
        </MenuIcon>

        <>
          <MiddleBars
            showDesktop
            showMobile={false}
            setModalOpen={setLocationModal}
            setStagedLocation={setStagedLocation}
          />
        </>

        <NavUL className={`mobile ${isMenuOpen && 'is-open'}`}>
          <NavLI className="first-element">
            <Link
              to="/menu"
              className="shop-now"
              onClick={() => setIsMenuOpen(false)}
            >
              SHOP NOW
            </Link>
          </NavLI>
          <NavLI
            onMouseEnter={() => handleOnMouseEnter('stores')}
            onClick={() => handleNavLIClick('stores')}
          >
            <Link
              to="/stores"
              className="nav-link--mobile-disabled"
              onClick={() => setIsMenuOpen(false)}
            >
              STORES
            </Link>
            <DropdownStores
              id="stores"
              dropdownState={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              setIsMenuOpen={setIsMenuOpen}
            />
          </NavLI>
          <NavLI>
            <div
              onClick={() => {
                handleNavLIClick('delivery');
              }}
            >
              DELIVERY
            </div>
            <DropdownDelivery
              id="delivery"
              dropdownState={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              setIsMenuOpen={setIsMenuOpen}
            />
          </NavLI>
          <NavLI>
            <Link to="/blog" onClick={() => setIsMenuOpen(false)}>
              BLOG
            </Link>
          </NavLI>
        </NavUL>

        <MobileLocation
          setModalOpen={setLocationModal}
          setStagedLocation={setStagedLocation}
        />

        <NavGroup>
          <CTAButton
            className="hoc-button-outline"
            onClick={() => setIsModalOpen(true)}
          >
            SUBSCRIBE
          </CTAButton>

          <CartButton onClick={() => setCartToggle(!cartToggle)}>
            <img src={Cart} alt="cart Img" />{' '}
            <a className="amount">
              (
              {checkout && checkout.items.length > 0
                ? checkout.items
                    .map((item) => item.quantity)
                    .reduce((x, y) => x + y)
                : 0}
              )
            </a>
          </CartButton>
        </NavGroup>

        <NavUL
          className={`desktop ${isMenuOpen && 'is-open'}`}
          onMouseLeave={() => setSelectedDropdown('')}
        >
          <NavLI
            className="first-element"
            onMouseEnter={() => handleOnMouseEnter('')}
          >
            <Link
              to="/menu"
              className="shop-now"
              onClick={() => setIsMenuOpen(false)}
            >
              SHOP NOW
            </Link>
          </NavLI>
          <NavLI
            onMouseEnter={() => handleOnMouseEnter('stores')}
            onClick={() => handleNavLIClick('stores')}
          >
            <Link
              to="/stores"
              className="nav-link--mobile-disabled"
              onClick={() => setIsMenuOpen(false)}
            >
              STORES
            </Link>
            <DropdownStores
              id="stores"
              dropdownState={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              setIsMenuOpen={setIsMenuOpen}
            />
          </NavLI>
          <NavLI
            onMouseEnter={() => handleOnMouseEnter('delivery')}
            onClick={() => handleNavLIClick('stores')}
          >
            <div onClick={() => setIsMenuOpen(false)}>DELIVERY</div>
            <DropdownDelivery
              id="delivery"
              dropdownState={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              setIsMenuOpen={setIsMenuOpen}
            />
          </NavLI>
          <NavLI onMouseEnter={() => handleOnMouseEnter('')}>
            <Link to="/blog" onClick={() => setIsMenuOpen(false)}>
              BLOG
            </Link>
          </NavLI>
        </NavUL>

        <MiddleBars
          showDesktop={false}
          showMobile
          // searchTerm={searchTerm}
          // setSearchTerm={setSearchTerm}
        />
      </Nav>
      <SlidingBanner message={promoText} showDesktop={false} showMobile />
      <SideCart toggle={cartToggle} setToggle={setCartToggle} />
    </>
  );
}
