import { graphql, useStaticQuery } from 'gatsby';

export const useDutchieLocation = () => {
  const data = useStaticQuery(graphql`
    {
      dutchieplus {
        retailers {
          name
          id
        }
      }
    }
  `);
  let locale = '';
  if (typeof window !== 'undefined') {
    locale = JSON.parse(
      window.localStorage.getItem('dutchie-plus--retailer-id')
    );
  }
  const retailers = data?.dutchieplus?.retailers || [];
  const localeSlug = retailers.find((item, i) => locale === item.id);
  return localeSlug?.name || ' ';
};
