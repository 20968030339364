import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import {MdOutlineArrowBackIosNew} from 'react-icons/md';

const MOBILE_BREAKPOINT = '960px';



const Container = styled.div`
  position: absolute;
  top: 180px;
  left: 0;
  z-index: 100;
  visibility: ${(props) => (props.id === props.dropdownState ? 'visible' : 'hidden')};
  width: 100vw;
  max-height: ${(props) => (props.id === props.dropdownState ? '100%' : '0')};
  max-height: 50%;
  background-color: #fafafa;
  box-shadow: inset 0px 4px 15px 5px rgba(60, 100, 177, 0.05);
  overflow: hidden;
  transition: max-width 1s, max-height 1s;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    top: -5px;
    right: 0;
    left: 0;
    max-width: ${(props) => (props.id === props.dropdownState ? '2000px' : '0')};
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
  }
`;

const Content = styled.div`
  display: grid;
  visibility: inherit;
  margin: 3vw;
  padding: 2vw;;
  text-align: left;
  background: white;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0vw;
    padding: 2vw;
    background: rgb(229,229,229);
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgba(255,255,255,1) 9%);
  }
`;

const MobileButton = styled.div`
  font-family: Oswald;
  color: var(--darkgreen);
  font-weight: 500;
  font-size: 24px;
  width: 100%;
  text-align: center;
  padding: 32px 0px;
  
  @media (min-width: ${MOBILE_BREAKPOINT}){
    display: none;
  }
`;

const ContentTitle = styled.h2`
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 100%;
  text-align: left;
  color: #2d2b2c;
  text-transform: none;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: block;
    text-align: center;
    font-size: 18px;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  visibility: inherit;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
  text-align: left;
  background: white;
  padding: 3.25vw 0px;
  padding-bottom: 0px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    padding-top: 20px;
    background: transparent;
  }
`;

const TabTitle = styled.div`
  margin-bottom: 35px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  text-decoration: ${(props) => (props.isSelected ? 'underline' : 'none')};
  color: ${(props) => (props.isSelected ? '#2D2B2C' : '#6D6E71')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:hover{
    a{
      color: var(--darkgreen);
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
    max-width: 300px;
    margin: 0px auto;
    padding: 20px 0px;
    justify-content: center;
    font-size: 15px;
    border-top: 2px solid rgba(109,110,113, 0.1);
  }
  &:nth-of-type(1) {
    border-top: 0px;
  }
`;


export default function DropdownStores({ id, dropdownState, setIsMenuOpen, setSelectedDropdown }) {
  const rawdata = useStaticQuery(
    graphql`
      query{
        allStrapiDeliveryLocations {
          nodes {
            Location
            slug
          }
        }
      }
    `
  );
  const data = rawdata?.allStrapiDeliveryLocations?.nodes;

  return (
    <Container id={id} dropdownState={dropdownState}>
      <Content>
        <MobileButton onClick={() => {
          setSelectedDropdown('');
          }}>
            
          <MdOutlineArrowBackIosNew/> Delivery
        </MobileButton>
        <ContentTitle>Same Day Weed Delivery Locations</ContentTitle>
        <ContentGrid rowNumber={5}>
          {data.map((location, key) => (
              <TabTitle key={key} onClick={() => setIsMenuOpen(false)}>
                <Link to={'/delivery/'+location.slug}>{location.Location}</Link>
              </TabTitle>
          ))}
        </ContentGrid>
      </Content>      
    </Container>
  );
}
