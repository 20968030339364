export default {
  disjunctiveFacets: ['stores', 'store_ids', 'category', 'subcategory', 'brand_name', 
  'strain_type', 'effects', 'price', 'thc', 'cbd', 'min_thc', 'max_thc', 'min_cbd', 
  'max_cbd', 'stores_ids', 'stores', 'store_addys', 'slugs', 'store_ids',
  'store_id', 'store', 'store_addy'],
  search_fields: {
    name: {},
    brand_name: {},
  },
  result_fields: {
    id: { raw: {} },
    name: { raw: {} },
    description: { raw: {} },
    category: { raw: {} },
    subcategory: { raw: {} },
    image: { raw: {} },
    strain_type: { raw: {} },
    brand_name: { raw: {} },
    effects: { raw: {} },
    price: { raw: {} },
    variants: { raw: {} },
    slug: { raw: {} },
    stores: { raw: {} },
    store_ids: {raw: {} },
    min_cbd: { raw: {} },
    max_cbd: { raw: {} },
    thc: { raw: {} },
    cbd: { raw: {} },
    weights: { raw: {} },
    min_thc: { raw: {} },
    max_thc: { raw: {} },
    store_ids: { raw: {}},
    stores: {raw: {}},
    store_addys: {raw: {}},
    slugs: {raw: {}},
    store_id: {raw: {}}
  },
  facets: {
    category: { type: 'value', size: 30 },
    subcategory: { type: 'value', size: 30 },
    strain_type: { type: 'value', size: 30 },
    brand_name: { type: 'value', size: 30 },
    effects: { type: 'value', size: 30 },
    stores: { type: 'value', size: 30 },
    weights: { type: 'value' },
    price: {type: 'value'},
    thc: {type: 'value'},
    min_thc: {type: 'value'},
    max_thc: {type: 'value'},
    min_cbd: {type: 'value'},
    max_cbd: {type: 'value'},
    cbd: {type: 'value'},
    store_id: {type: 'value'},
    store: {type: 'value'},
    store_addy: {type: 'value'},
  },
};
