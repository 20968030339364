import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby-link';

import { FaSearch } from 'react-icons/fa';
import Select from 'react-select';
import mapIcon from '../../assets/images/mapIcon.svg';

import { CheckoutContext } from '../apolloMenu/contexts/checkout';

const MOBILE_BREAKPOINT = '768px';

const Wrapper = styled.div`
  //height: 65px;
  width: fit-content;
  display: none;
  align-items: center;
  justify-content: center;

  margin-top: 10px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
  }
`;
const Location = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  .icon {
    color: rgba(109, 110, 113, 0.5);
  }

  .text {
    margin: 0 5px 0 10px;
    text-transform: capitalize;

    font-family: Lato;
    font-size: 12px;

    width: fit-content;
  }
`;

const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: 60,
    height: '100%',
    fontFamily: 'Lato',
    fontSize: 13,
    // backgroundColor: '#F5F5F5',
  }),

  dropdownIndicator: (provided) => ({
    display: 'none',
  }),

  control: (provided) => ({
    ...provided,
    padding: '0',
    // backgroundColor: '#F5F5F5',
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
    color: 'black',
  }),

  valueContainer: (provided) => ({
    ...provided,
    // background: '#F5F5F5',
    color: 'black',
    width: '100%',
    padding: 0,
  }),

  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'Lato',
    fontSize: 12,
    textTransform: 'capitalize',
    color: 'black',
    fontWeight: 600,
  }),

  menuPortal: (provided) => ({
    ...provided,
    zIndex: 100,
  }),

  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    fontSize: 12,
    width: 150,
    textTransform: 'capitalize',
    color: 'black',
    borderRadius: 0,
    backgroundColor: '#F5F5F5',
  }),

  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'gray' : 'black',
    backgroundColor: '#F5F5F5',
    ':hover': {
      backgroundColor: 'gray',
    },
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export default function MobileLocation({ setModalOpen, setStagedLocation }) {
  const { retailerList, currentRetailer, setCurrentRetailer } =
    useContext(CheckoutContext);

  const [retailer, setRetailer] = useState('');
  const [listofretailers, setListOfRetailers] = useState([]);

  useEffect(() => {
    if (currentRetailer && retailerList) {
      const _curRetailer = retailerList.find(
        (_retailer) => _retailer.id === currentRetailer
      );
      if (_curRetailer)
        setRetailer({
          value: _curRetailer.id,
          label: _curRetailer?.name?.split(' - ')[1],
        });

      const _listOfRetailers = retailerList.map((_retailer) => ({
        value: _retailer.id,
        label: _retailer?.name?.split(' - ')[1],
      }));
      setListOfRetailers(_listOfRetailers);
    }
  }, [currentRetailer, retailerList]);

  const onHandleLocationChange = (option) => {
    // setCurrentRetailer(option.value);
    setModalOpen(true);
    setStagedLocation(option.value);
  };

  return (
    <Wrapper>
      <Location>
        <p className="text">
          Current Store:
          {/* <strong>{retailer}</strong> */}
        </p>
        <Select
          onChange={onHandleLocationChange}
          value={retailer}
          options={listofretailers}
          styles={selectStyles}
        />
      </Location>
    </Wrapper>
  );
}
