/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import useLocalStorage from './useLocalStorage';

import CHECKOUT_QUERY from '../queries/checkout.graphql';
import CREATE_CHECKOUT from '../queries/mutations/create-checkout.graphql';
import ADD_ITEM_TO_CHECKOUT from '../queries/mutations/add-item-to-checkout.graphql';
import REMOVE_ITEM_FROM_CHECKOUT from '../queries/mutations/remove-item-from-checkout.graphql';
import UPDATE_CHECKOUT_ITEM_QUANTITY from '../queries/mutations/update-checkout-item-quantity.graphql';
import UPDATE_CHECKOUT from '../queries/mutations/update-checkout.graphql';
import RETAILER_LIST_QUERY from '../queries/retailerList.graphql';
import { filterRetailers } from '../../../helpers/retailers';
import { logMissingFieldErrors } from '@apollo/client/core/ObservableQuery';

const useCheckout = (initialOrderType, initialPricingType) => {
  // Local Storage States
  const [checkoutId, setCheckoutId] = useLocalStorage('dutchie-plus--checkout-id');
  const [currentRetailer, setCurrentRetailer] = useLocalStorage('dutchie-plus--retailer-id');
  const [retailerList, setRetailerList] = useState();
  // Query
  const {
    data: retailerData,
    loading: retailerLoading,
    error: retailerError,
  } = useQuery(RETAILER_LIST_QUERY);
  const {
    data: checkoutData,
    loading: checkoutLoading,
    error: checkoutError,
    refetch: refetchCheckout,
  } = useQuery(CHECKOUT_QUERY, {
    variables: { id: checkoutId, retailerId: currentRetailer },
    fetchPolicy: 'network-only', // Used for subsequent executions
  });

  // Mutations
  const [
    createCheckout,
    { data: createCheckoutData, loading: createCheckoutLoading, error: createCheckoutError },
  ] = useMutation(CREATE_CHECKOUT);
  const [
    updateCheckout,
    { data: updateCheckoutData, loading: updateCheckoutLoading, error: updateCheckoutError },
  ] = useMutation(UPDATE_CHECKOUT);
  const [
    addItemToCheckout,
    { data: addToCartData, loading: addToCartLoading, error: addToCartError },
  ] = useMutation(ADD_ITEM_TO_CHECKOUT);
  const [
    removeItemFromCheckout,
    { data: removeFromCartData, loading: removeFromCartLoading, error: removeFromCartError },
  ] = useMutation(REMOVE_ITEM_FROM_CHECKOUT);
  const [
    updateCheckoutItemQuantity,
    { data: updateQuantityData, loading: updateQuantityLoading, error: updateQuantityError },
  ] = useMutation(UPDATE_CHECKOUT_ITEM_QUANTITY);

  // States
  const [checkout, setCheckout] = useState();
  const [orderType, setOrderType] = useState(initialOrderType);
  const [pricingType, setPricingType] = useState(initialPricingType);

  // Set checkout data
  useEffect(() => {
    if (!retailerLoading) {
      setRetailerList(filterRetailers(retailerData.retailers, 'name'))
      if (
        retailerList &&
        (!currentRetailer || retailerList?.filter((x) => x.id === currentRetailer).length < 1)
      ) {
        setCurrentRetailer(retailerList[0].id);
      }
    }
  }, [retailerLoading, retailerData?.retailers]);
  useEffect(() => {
    if (currentRetailer) {
      console.log('CURRENT RETAILER', currentRetailer);
      if (!checkoutLoading && checkoutData) {
        setCheckout(checkoutData.checkout);
        setPricingType(checkoutData.checkout.pricingType);
        setOrderType(checkoutData.checkout.orderType);
      }
      console.log('CHECKOUTID', checkoutId);
    }
  }, [checkoutLoading, checkoutData, currentRetailer]);

  // If loading the checkout failed, create a new checkout
  useEffect(() => {
    if (checkoutError) {
      console.log(` checkoutError-->${checkoutError}`);
      createCheckoutAsync(orderType, pricingType, currentRetailer);
      refetchCheckout();
    }
  }, [checkoutError]);

  // Update checkout if Order or Pricing Type changes
  useEffect(() => {
    if (checkout && (checkout.orderType !== orderType || checkout.pricingType !== pricingType))
      updateCheckoutAsync(orderType, pricingType);
  }, [orderType, pricingType]);

  const updateStateFromCheckout = (checkout) => {
    setCheckoutId(checkout.id);
    setCheckout(checkout);
    setPricingType(checkout.pricingType);
    setOrderType(checkout.orderType);
  };

  // Create a checkout asynchronously
  const createCheckoutAsync = async (orderType, pricingType, currentRetailer) => {
    try {
      const result = await createCheckout({
        variables: { orderType, pricingType, retailerId: currentRetailer },
      });
      updateStateFromCheckout(result.data.createCheckout);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  const updateCheckoutAsync = async (orderType, pricingType) => {
    try {
      const result = await updateCheckout({
        variables: {
          checkoutId,
          orderType,
          pricingType,
          retailerId: currentRetailer,
        },
      });

      setCheckoutId(result.data.updateCheckout.id);
      setCheckout(result.data.updateCheckout);

      return result.data.updateCheckout;
    } catch (e) {
      // console.log(e);
      return undefined;
    }
  };

  // Wrapper Functions for Error Handling
  const addToCart = async (productId, quantity, option) => {
    try {
      const result = await addItemToCheckout({
        variables: {
          retailerId: currentRetailer,
          checkoutId,
          productId,
          quantity,
          option,
        },
      });

      updateStateFromCheckout(result.data.addItem);

      return checkout;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  const removeFromCart = async (itemId) => {
    try {
      const result = await removeItemFromCheckout({
        variables: { checkoutId, itemId, retailerId: currentRetailer },
      });

      updateStateFromCheckout(result.data.removeItem);
      return checkout;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  const updateQuantity = async (itemId, quantity) => {
    try {
      const result = await updateCheckoutItemQuantity({
        variables: {
          checkoutId,
          itemId,
          quantity,
          retailerId: currentRetailer,
        },
      });
      console.log('updateQuantity result.data.updateItem:');
      console.log(result.data.updateItem);
      console.log('updateQuantity result.data:');
      console.log(result.data);
      updateStateFromCheckout(result.data.updateQuantity);
      return checkout;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  return {
    setOrderType,
    setPricingType,
    setCurrentRetailer,
    orderType,
    pricingType,
    checkoutId,
    checkout,
    checkoutLoading,
    checkoutError,
    currentRetailer,
    retailerList,
    // Functions
    createCheckoutAsync,
    updateCheckoutAsync,
    addToCart,
    removeFromCart,
    updateQuantity,
    setRetailerList,
  };
};
export default useCheckout;
