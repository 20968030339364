import { createGlobalStyle } from 'styled-components';


const Typography = createGlobalStyle`
  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    color: var(--black);
  }
  p, li {
    letter-spacing: 0.5px;
    font-family: Lato;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
    font-family: Oswald;
    color: var(--black);
  }
  h1{
    color: white;
    text-transform: uppercase;
    font-weight: 700;
  }
  h2{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 36px;
  }
  h3{
    color: var(--darkgreen);
    font-family: Lato;
    font-size: 18px;
  }
  a{
    color: inherit;
    text-decoration: none;
    &:hover{
      color: inherift;
    }
    &:visited{
      color: inherift;
    }
    &:active{
      color: inherift;
    }
  }
  mark, .mark {
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  nav{
    font-family: Oswald;
    text-transform: uppercase;
  }

  ul, li{
    font-family: Oswald;
    text-transform: uppercase;
    font-size: 17px;
  }


  .hoc-button, .hoc-button-outline{
    font-family: Oswald;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 17px;
  }
  .hoc-button-outline{
    color: var(--darkgreen);
    &:hover{
      color: white;
    }
  }
`;

export default Typography;
